<form libEnterKeyHandler="submit_modal_dummy" [formGroup]="employeeLoginForm" class="employee-login-modal">
  <label class="name">
    <span class="name__label">{{ 'EMPLOYEEREGISTRATION.NAME' | translate }}</span>
    <span class="name__value" [innerHTML]="name"></span>
  </label>

  <label class="logintime">
    <span class="logintime__label">{{ 'EMPLOYEEREGISTRATION.LOGINTIME' | translate }}</span>
    <span class="logintime__value">
      {{ loginTime | date : 'dd.MM.YYYY HH:mm' }}
    </span>
  </label>

  <label class="employeeid">
    <span class="employeeid__label">{{ 'EMPLOYEEREGISTRATION.ID' | translate }}</span>
    <span class="employeeid__value" [innerHTML]="externalEmployeeId"></span>
  </label>

  <label class="role" [ngClass]="{ validations__required: role.invalid && role.errors.required }">
    <span class="role__label">{{ 'EMPLOYEEREGISTRATION.ROLE' | translate }}</span>
    <lib-dropdown
      [inlineStyle]="INPUT_STYLE"
      [options]="roles"
      [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
      formControlName="role"
    ></lib-dropdown>
  </label>

  <label class="note">
    <span class="note__label">{{ 'EMPLOYEEREGISTRATION.NOTE' | translate }}</span>
    <input class="note_value" type="text" pInputText size="50" [maxLength]="250" formControlName="note" />
  </label>

  <label *ngIf="registrationPurpose" class="purpose">
    <span class="purpose__label">{{ 'EMPLOYEEREGISTRATION.REGISTRATION_PURPOSE' | translate }}</span>
    <span class="purpose__value" [innerHTML]="registrationPurpose"></span>
  </label>
</form>
<lib-modal-footer
  [submitId]="'submit_modal_dummy'"
  [submitLabel]="'EMPLOYEEREGISTRATION.LOGIN' | translate"
  (submitClicked)="onSubmitModal()"
  (declineClicked)="onCloseModal()"
  [whenLoadingTopic]="LOADING_TOPIC_LOGIN"
  [submitDisabled]="employeeLoginForm.invalid"
></lib-modal-footer>
