import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveOrderDsService, BillOfMaterialDsService, QaHistoryDsService } from '@app/core/data-services';
import { FinishSetupCommandService, PreviousSetupSubphaseCommandService } from '@app/modules/setup-phase/commands';
import { SetupFooterService } from '@app/shared/components';
import { BillOfMaterialColSizes } from '@app/shared/components/bill-of-material/bill-of-material.model';
import { nav, setupPhaseFooterRoutes } from '@app/shared/utils';
import { Observable, Subscription } from 'rxjs';
import { SetupPhaseService } from '../../services/setup-phase/setup-phase.service';
import { BillOfMaterial, RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { AppSettingsQuery } from 'chronos-shared';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-waste-assignment',
  templateUrl: './waste-assignment.component.html',
  styleUrls: ['./waste-assignment.component.scss']
})
export class WasteAssignmentComponent implements OnInit, OnDestroy {
  public billOfMaterials$: Observable<BillOfMaterial[]>;
  public readonly bomColSizes: BillOfMaterialColSizes = {
    onHandColSize: '180px',
    openColSize: '180px',
    mountedColSize: '180px',
    nameColSize: '480px',
    articleColSize: '180px',
    statusColSize: '180px'
  };

  public backCommandService: PreviousSetupSubphaseCommandService;
  public finishSetupCommandService: FinishSetupCommandService;
  public showInactive: boolean;

  private subscriptions = new Subscription();

  constructor(
    private activeOrderDsService: ActiveOrderDsService,
    private billOfMaterialDsService: BillOfMaterialDsService,
    private setupPhaseService: SetupPhaseService,
    private setupFooterService: SetupFooterService,
    private qaHistoryDsService: QaHistoryDsService,
    private appSettingsQuery: AppSettingsQuery,
    private signatureHandlerService: SignatureHandlerService
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.appSettingsQuery.showInactiveElement();
    const productionOrderId = this.activeOrderDsService.getActiveOrderId();
    this.billOfMaterials$ = this.billOfMaterialDsService.getBillOfMaterials(productionOrderId);

    this.setCommands();

    this.subscriptions.add(
      this.qaHistoryDsService.isQaChecksAvailableForSetup().subscribe((isAvailable) => {
        this.updateBackCommand(isAvailable);
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onWasteValidationChange(isValid: boolean): void {
    this.finishSetupCommandService.enabled = isValid;
  }

  private setCommands(): void {
    this.backCommandService = new PreviousSetupSubphaseCommandService(this.setupFooterService);
    this.backCommandService.enabled = true;

    this.finishSetupCommandService = new FinishSetupCommandService(this.setupPhaseService);
    this.finishSetupCommandService.setNavigationParams(nav.routes.run);
    this.finishSetupCommandService.buttonText = 'SETUP_PHASE_FOOTER.FINISH';

    this.setupPhaseService.setSetupCommands(this.backCommandService, this.finishSetupCommandService);
  }

  private setBackCommandExecution() {
    this.backCommandService.execute = () => {
      this.setupFooterService
        .goToNextSubPhase(
          nav.routes.qualityAssurance,
          RunPhaseType.SETUP,
          RunSubPhaseType.SETUP_IN_PROGRESS_FINISHING_CHECK,
          setupPhaseFooterRoutes
        )
        .pipe(finalize(() => {}))
        .subscribe();
    };
  }

  private updateBackCommand(isQaAvailable: boolean): void {
    if (isQaAvailable) {
      this.backCommandService.setNavigationParams(nav.routes.qualityAssurance);
      this.setBackCommandExecution();
    } else {
      this.backCommandService.setNavigationParams(nav.routes.setupParameters);
    }
  }
}
