import { Injectable } from '@angular/core';
import {
  Setting,
  SettingHierarchyViewModel,
  SettingValuesViewModel,
  SettingsService,
  SettingEntityInfoViewModel,
  SettingHistoryViewModel,
  UpdateSettingEntry,
  ImportSettingsEntry,
  ExportSettingsResponse,
  UpdateSettingsBatchEntry,
  SettingValueRaw
} from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingDsService {
  constructor(private settingsService: SettingsService) {}

  public getSettings(): Observable<Setting[]> {
    return this.settingsService.getSettings();
  }

  public getSettingValues(hierarchyType: string, hierarchyLevel: string, entityId: string): Observable<SettingValuesViewModel[]> {
    const params: SettingsService.GetSettingValuesParams = {
      hierarchyType,
      hierarchyLevel,
      entityId: encodeURIComponent(entityId)
    };
    return this.settingsService.getSettingValues(params);
  }

  public getSettingHierarchy(hierarchyType: string): Observable<SettingHierarchyViewModel> {
    return this.settingsService.getSettingHierarchy(hierarchyType);
  }

  public getSettingEntityInfo(hierarchyType: string, hierarchyLevel: string, entityId: string): Observable<SettingEntityInfoViewModel> {
    const params: SettingsService.GetSettingEntityInfoParams = {
      hierarchyType,
      hierarchyLevel,
      entityId: encodeURIComponent(entityId)
    };
    return this.settingsService.getSettingEntityInfo(params);
  }

  public getSettingHistory(
    settingId: number,
    hierarchyType: string,
    hierarchyLevel: string,
    entityId: string
  ): Observable<SettingHistoryViewModel[]> {
    const params: SettingsService.GetSettingHistoryParams = {
      settingId,
      hierarchyType,
      hierarchyLevel,
      entityId: encodeURIComponent(entityId)
    };
    return this.settingsService.getSettingHistory(params);
  }

  public updateSetting(
    settingId: number,
    hierarchyLevel: string,
    externalId: string,
    entry: UpdateSettingEntry
  ): Observable<SettingHistoryViewModel> {
    const params: SettingsService.UpdateSettingParams = {
      settingId,
      hierarchyLevel,
      externalId,
      entry
    };

    return this.settingsService.updateSetting(params);
  }

  public importSettings(csvFile: string, updatedBy: string): Observable<SettingHistoryViewModel> {
    const entry: ImportSettingsEntry = {
      csvFile,
      updatedBy
    };
    return this.settingsService.importSettings(entry);
  }

  public exportSettings(): Observable<ExportSettingsResponse> {
    return this.settingsService.exportSettings();
  }

  public updateSettingsBatch(
    hierarchyLevel: string,
    externalId: string,
    settings: Array<SettingValueRaw>,
    updatedBy: string
  ): Observable<null> {
    const entry: UpdateSettingsBatchEntry = {
      settingValuesRaw: settings,
      updatedBy
    };

    const param: SettingsService.UpdateSettingsBatchParams = {
      hierarchyLevel,
      externalId,
      entry
    };

    return this.settingsService.updateSettingsBatch(param);
  }
}
