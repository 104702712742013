<div [formGroup]="parentForm" class="row qa-check-element">
  <div (click)="toggleContent()" class="col-4 qa-check-element__item">
    <i
      [ngClass]="{
        'icon-arrow-right-filled-open': isCollapsed,
        'icon-arrows-right-filled-close': !isCollapsed
      }"
      class="collapse-icon"
    ></i>
    <span class="progress__item-title">{{ field.label }}</span>
  </div>
  <div class="col-3">
    <div class="row qa-check-element">
      <div class="col-9 qa-check-element__item">
        <lib-input-number
          formControlName="measurementResult"
          [unit]="field.measurementResultUnitId"
          [inputStyle]="INPUT_STYLE"
          *ngIf="isMeasurementUnitVisible()"
        ></lib-input-number>
      </div>
      <div class="col-3 qa-check-element__item--border qa-check-element__item criticalflag">
        <span *ngIf="field.isCritical">
          <i class="icon-qarning-outline criticalflagicon"></i>
        </span>
      </div>
    </div>
  </div>
  <div
    class="col-sm-auto qa-check-element__item result-component"
    [ngClass]="{ 'qa-check-element__item--required': parentForm.get('check').hasError('required') }"
  >
    <app-result-select-button formControlName="check" [isReadOnly]="isReadOnly" [name]="field.label"></app-result-select-button>
  </div>
  <div class="col qa-check-element__item">
    <input class="qa-check-element__info-field" type="text" formControlName="comment" [readonly]="isReadOnly" pInputText />
  </div>
</div>
<div *ngIf="!isCollapsed" class="row no-gutters qa-check-element">
  <div class="col-12 qa-check-element__item qa-check-element__item--expanded">{{ field.description }}</div>
</div>
