<div
  class="parameter-row"
  [ngClass]="{
    'error-row': shouldShowError(),
    'warning-row': shouldShowWarning()
  }"
>
  <label [ngSwitch]="parameterField.controlType" [for]="parameterField.id" class="parameter-label">
    <span *ngSwitchCase="PARAMETER_FIELD_TYPES.PalletQuantity">
      {{ 'PARAMETER_FIELD.' + parameterField.label | translate }}
      {{ 'PARAMETER_FIELD.' + (productionOrder.articleClassification | uppercase) | translate }}
    </span>
    <span *ngSwitchCase="PARAMETER_FIELD_TYPES.BobbinQuantity">{{ 'PARAMETER_FIELD.' + parameterField.label | translate }}</span>
    <span *ngSwitchDefault>
      <span>
        {{ (parameterField.label === 'CORE_LABEL_COPIES' ? 'PARAMETER_FIELD.CORE_LABEL_COPIES' : parameterField.label) | translate }}
      </span>
    </span>
  </label>
  <span [ngSwitch]="parameterField.controlType" class="parameter-field">
    <lib-input-number
      *ngSwitchCase="PARAMETER_FIELD_TYPES.Text"
      [formControl]="controlField"
      [inputId]="parameterField.id"
      (touched)="saveFieldOnBlur()"
      [inputStyle]="INPUT_STYLE"
    ></lib-input-number>
    <lib-dropdown
      *ngSwitchCase="PARAMETER_FIELD_TYPES.Dropdown"
      [formControl]="controlField"
      [inputId]="parameterField.id"
      [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
      [options]="dropdownOptions"
      (touched)="saveFieldOnBlur()"
    ></lib-dropdown>
    <app-pallet-quantity-parameter
      *ngSwitchCase="PARAMETER_FIELD_TYPES.PalletQuantity"
      [formControl]="controlField"
      [inputId]="parameterField.id"
      [totalOrderQuantity]="
        productionOrder.isGoodQuantityPredecessorEnabled === true
          ? productionOrder.predecessorsGoodQuantity
          : productionOrder.estimatedQuantity
      "
      (touched)="saveFieldOnBlur()"
    ></app-pallet-quantity-parameter>
    <app-pallet-bobbin-quantity-parameter
      *ngSwitchCase="PARAMETER_FIELD_TYPES.BobbinQuantity"
      [formControl]="controlField"
      [inputId]="parameterField.id"
      [totalOrderQuantity]="
        productionOrder.isGoodQuantityPredecessorEnabled === true
          ? productionOrder.predecessorsGoodQuantity
          : productionOrder.estimatedQuantity
      "
      [unit]="
        productionOrder.isGoodQuantityPredecessorEnabled === true
          ? productionOrder.predecessorsGoodQuantity.unitId
          : productionOrder.estimatedQuantity.unitId
      "
      [slitCount]="productionOrder.slitCount"
      [defaultValue]="parameterField.defaultValue"
      (touched)="saveFieldOnBlur()"
    ></app-pallet-bobbin-quantity-parameter>
  </span>
  <span class="parameter-row__icon">
    <i [ngClass]="shouldShowError() ? 'icon-error-solid error-icon' : 'icon-info-outline info-icon'"></i>
  </span>
</div>
