<lib-header-layout>
  <lib-title
    [applicationName]="'TITLE.COCKPIT_APPLICATION' | translate"
    [workCenterName]="appSettingsQuery.workCenterName$ | async"
  ></lib-title>
  <lib-info-log></lib-info-log>
  <lib-version-number [version]="version"></lib-version-number>
  <lib-multi-language>
    <lib-dropdown
      [options]="supportedLanguages$ | async"
      [ngModel]="currentLanguage$ | async"
      (ngModelChange)="onLanguageSelected($event)"
      [inlineStyle]="{ 'min-width': '125px' }"
      [virtualScroll]="true"
      [panelWidth]="'125px'"
    ></lib-dropdown>
  </lib-multi-language>
  <lib-user
    [isEmployeeRegistrationEnabled]="isEmployeeRegistrationEnabled"
    [employeeName]="activeEmployeeRegistration"
    (userLoginClicked)="onUserClick()"
  >
    <ng-container *ngIf="hasWorkCenterSelection$ | async">
      <lib-dropdown
        [options]="workCenterOptions$ | async"
        [(ngModel)]="currentWorkCenter"
        (ngModelChange)="onWorkCenterChange($event)"
        [inlineStyle]="{ width: '180px' }"
        [disabled]="disabledWorkCenter"
        [virtualScroll]="true"
        [virtualScrollItemSize]="40"
        [panelWidth]="'300px'"
      ></lib-dropdown>
    </ng-container>
  </lib-user>
  <lib-environment *ngIf="(isProductionEnvironment$ | async) === false" [message]="'HEADER.NON_PRODUCTION' | translate"></lib-environment>
</lib-header-layout>
