import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment-mini';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { isNil } from 'ramda';

export const TIME_INPUT_PROVIDERS: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimeInputComponent),
  multi: true
};

export const TIME_INPUT_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TimeInputComponent),
  multi: true
};

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [TIME_INPUT_PROVIDERS, TIME_INPUT_VALIDATORS]
})
export class TimeInputComponent implements OnInit, ControlValueAccessor, Validator {
  private _startTime: Date;

  @Input()
  public get startTime(): Date {
    return this._startTime;
  }

  public set startTime(value: Date) {
    this._startTime = value;
    this.timeValueSubject.next(value);
  }

  @Input()
  public endTime: Date;

  @Input()
  public showControls = true;

  @Input()
  public bypassDayCorrection = true;

  @Input()
  public disabled = false;

  @Output()
  public inputChanged: EventEmitter<Date> = new EventEmitter();

  @ViewChild('hours', { read: ElementRef }) public hourRef: ElementRef;
  @ViewChild('minutes', { read: ElementRef }) public minuteRef: ElementRef;

  public timeDefineFieldStyle = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invalid: { color: '#c70000', 'border-color': '#c70000', width: '40px' },
    valid: { width: '40px' }
  };

  private onChange: (result: Date) => void;
  private onTouched: () => void;

  private timeValueSubject = new BehaviorSubject<Date>(null);
  public timeValue$ = this.timeValueSubject.asObservable();

  public ngOnInit(): void {
    this.timeValue$.subscribe((timeValue) => {
      this.emitInputChange(timeValue);
      if (!isNil(timeValue) && this.onChange) {
        this.onChange(timeValue);
      }
    });
  }

  private emitInputChange(timeValue) {
    this.inputChanged.emit(timeValue);
  }

  public increaseTimeByMinute(timeValue: Date): void {
    this.changeTimeByMinutes(timeValue, 1);
  }

  public decreaseTimeByMinute(timeValue: Date): void {
    this.changeTimeByMinutes(timeValue, -1);
  }

  public isTimeCorrect(timeValue: Date): boolean {
    return this.getTimeDifferenceInMinutes(timeValue, this.startTime) > 0 && this.getTimeDifferenceInMinutes(this.endTime, timeValue) > 0;
  }

  public changeTimeByMinutes(timeValue: Date, minuteChange: number): void {
    const changedTime = moment(timeValue).add(minuteChange, 'minutes').toDate();
    this.timeValueSubject.next(changedTime);
  }

  public onInputBlur(): void {
    setTimeout(() => {
      this.setInputValue(this.hourRef);
      this.setInputValue(this.minuteRef);
    }, 0);

    if (this.onTouched) {
      this.onTouched();
    }
  }

  private setInputValue(elementRef: ElementRef): void {
    if (elementRef) {
      const input = elementRef.nativeElement.querySelector('input');
      if (Number(input.value) < 10 && String(input.value).length < 2) {
        input.value = `0${input.value}`;
      }
    }
  }

  public setTimeValue(dateValue: Date, timeValue: number, unitId: 'm' | 'h'): void {
    const changedTimeValue = this.setTimeUnit(dateValue, timeValue, unitId);
    this.timeValueSubject.next(changedTimeValue);

    if (this.bypassDayCorrection) {
      return;
    }

    const timeValueWithCorrectDay = this.getDateDay(changedTimeValue);
    this.timeValueSubject.next(timeValueWithCorrectDay);
  }

  // Util functions
  public getTimeDifferenceInMinutes(greaterTime: Date, lowerTime: Date): number {
    return moment(greaterTime).diff(lowerTime, 'minutes');
  }

  public getDateDay(timeValue: Date): Date {
    if (!this.boundaryTimeExists()) {
      return this.setTimeUnit(timeValue, moment().days(), 'd');
    }

    return this.getDateFromBoundaries(timeValue);
  }

  private boundaryTimeExists(): boolean {
    return !!this.startTime && !!this.endTime;
  }

  private getDateFromBoundaries(timeValue: Date): Date {
    const timeFormat = 'HH:mm';
    if (moment(timeValue).format(timeFormat) > moment(this.endTime).format(timeFormat)) {
      return this.setTimeUnit(timeValue, moment(this.startTime).days(), 'd');
    }

    return this.setTimeUnit(timeValue, moment(this.endTime).days(), 'd');
  }

  public setTimeUnit(dateValue: Date, timeValue: number, unitId: 'm' | 'h' | 'd'): Date {
    return moment(dateValue).set(unitId, timeValue).toDate();
  }

  // Custom component interfaces implementation
  public writeValue(value: any): void {
    if (!isNil(value)) {
      this.timeValueSubject.next(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate({ value }: UntypedFormControl): ValidationErrors | null {
    const invalid = !this.isTimeCorrect(value);
    return (
      invalid && {
        timeIsNotCorrect: true
      }
    );
  }

  public getTimeInputStyles(timeValue: Date): { [key: string]: string } {
    if (this.boundaryTimeExists() && !this.isTimeCorrect(timeValue)) {
      return this.timeDefineFieldStyle.invalid;
    } else {
      return this.timeDefineFieldStyle.valid;
    }
  }
}
