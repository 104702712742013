<div class="p-grid p-nogutter">
  <div class="p-col-3">
    <h2 class="parameters-form__title">{{ 'SETUP_PARAMS.TITLE' | translate }}</h2>
  </div>
  <div class="p-col-8 parameters-form__table">
    <div
      class="parameters-form__table-item"
      [ngClass]="{
        'parameters-form__table-item--error': dropdownError(),
        'parameters-form__table-item--warning': dropdownWarning()
      }"
    >
      <label class="parameters-form__table-item-label" [for]="'setupParameterKind'">
        {{ 'SETUP_PARAMS.KIND_LABEL' | translate }}
      </label>
      <div class="parameters-form__table-item-input">
        <lib-dropdown
          [formControl]="setupKind"
          [inputId]="'setupParameterKind'"
          [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
          [options]="setupParameterKindOptions"
          [loading]="dropdownLoading"
          appendTo="body"
        ></lib-dropdown>
      </div>
      <span class="parameters-form__table-item-icon">
        <i
          [ngClass]="
            dropdownError()
              ? 'icon-error-solid parameters-form__table-item-icon--error'
              : 'icon-info-outline parameters-form__table-item-icon--info'
          "
        ></i>
      </span>
    </div>
    <ng-container *ngIf="isSetupParamsFormVisible">
      <app-params-table [fields]="setupParameterFields.setup" [form]="form" [fieldName]="'setupParameters'"></app-params-table>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="isAdditionalParamsVisible">
  <div class="p-grid p-nogutter parameters-form__tools_form">
    <div class="p-col-3">
      <h2 class="parameters-form__title">{{ 'SETUP_PARAMS.ADDITIONAL_TITLE' | translate }}</h2>
    </div>
    <ng-container *ngIf="setupParameterFields.toolSetup.length > 0">
      <div class="p-col-8 parameters-form__table">
        <app-tool-setup
          [toolSetup]="setupParameterFields.toolSetup[0]"
          (formStatusEvent)="setToolsParamsFormStatus($event)"
          (renewParametersEvent)="renewSetupParameterFields()"
        ></app-tool-setup>
      </div>
    </ng-container>
    <ng-container *ngIf="isManualTransportFormVisible">
      <div class="p-col-8">
        <div class="parameters-form__table parameters-form__table-item">
          <label class="parameters-form__table-item-label">{{ 'PARAMETER_FIELD.MANUAL_TRANSPORT' | translate }}</label>
          <span class="parameters-form__table-item-input">
            <lib-input-switch
              [(ngModel)]="manualTransportValue"
              [disabled]="ismanualTransportReadOnly"
              (ngModelChange)="requestSaveField()"
            ></lib-input-switch>
          </span>
          <span class="parameters-form__table-item-icon">
            <i [ngClass]="'icon-info-outline parameters-form__table-item-icon--info'"></i>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="isOptionalParamsFormVisible">
  <div class="p-grid p-nogutter">
    <div class="p-col-3">
      <h2 class="parameters-form__title">{{ 'OPTIONAL_PARAMS.TITLE' | translate }}</h2>
    </div>
    <div class="p-col-8 parameters-form__table">
      <app-params-table [fields]="setupParameterFields.optional" [form]="form" [fieldName]="'optionalParameters'"></app-params-table>
    </div>
  </div>
</ng-container>
