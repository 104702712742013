import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OuterLabelType, ProducedMaterial, ProducedMaterialService, SetForwardedWasteEntry } from 'chronos-core-client';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class ProducedMaterialDsService {
  constructor(private producedMaterialService: ProducedMaterialService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public setProducedForwardedWaste(producedMaterial: ProducedMaterial): Observable<null> {
    // Forwarded waste must be either true or false, so if it's null, we don't need to make a request. This happens when the produced material is finished.
    if (producedMaterial.containsForwardedWaste == null) {
      return of(null);
    }
    const setForwardedWaste: SetForwardedWasteEntry = {
      containsForwardedWaste: producedMaterial.containsForwardedWaste,
      workCenterId: producedMaterial.producedWorkCenterId
    };
    const setForwardedWasteParams = {
      producedMaterialId: producedMaterial.producedMaterialId,
      entry: setForwardedWaste
    };
    return this.producedMaterialService.setForwardedWaste(setForwardedWasteParams);
  }

  public printBobbinCoreLabel(printLabel: BobbinPrintLabel): Observable<null> {
    const printBobbinCoreLabelParams = {
      producedMaterialId: printLabel.producedMaterialId,
      entry: printLabel.entry
    };
    return this.producedMaterialService.printBobbinCoreLabel(printBobbinCoreLabelParams);
  }

  public changeProducedMaterialPrintInfo(
    producedMaterialId: number,
    outerLabelType: OuterLabelType,
    quantityPerOuter: number,
    numberOfCopies: number
  ): Observable<null> {
    const changeProducedMaterialPrintInfoEntry = {
      numberCopies: numberOfCopies,
      outerLabelType,
      quantityPerOuter,
      workCenterId: this.activeWorkCenterService.getWorkCenterId()
    };

    const changeProducedMaterialPrintInfoParams = {
      producedMaterialId,
      entry: changeProducedMaterialPrintInfoEntry
    };

    return this.producedMaterialService.changeProducedMaterialPrintInfo(changeProducedMaterialPrintInfoParams);
  }
}
