import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QaHistoryDsService, PatternInfoDsService, ActiveOrderDsService } from '@app/core/data-services';
import { NextSetupSubphaseCommandService, PreviousSetupSubphaseCommandService } from '@app/modules/setup-phase/commands';
import { SetupPhaseService } from '@app/modules/setup-phase/services/setup-phase/setup-phase.service';
import { SetupFooterService } from '@app/shared/components';
import { nav, setupPhaseFooterRoutes } from '@app/shared/utils';
import { OuterPrintInfo, PrintLabelForOuterEntry, RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { SetupParametersService } from '@app/modules/setup-phase/services/setup-parameters/setup-parameters.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { ParametersFactoryService } from '@app/core/services/parameters-factory';
import { OuterInfo } from '@app/shared/models';
import { finalize } from 'rxjs/operators';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { AppSettingsQuery } from 'chronos-shared';

@Component({
  selector: 'app-setup-parameters',
  templateUrl: './setup-parameters.component.html',
  styleUrls: ['./setup-parameters.component.scss']
})
export class SetupParametersComponent implements OnInit, OnDestroy {
  public printInfo$: Observable<OuterPrintInfo>;
  public backCommandService: PreviousSetupSubphaseCommandService;
  public nextCommandService: NextSetupSubphaseCommandService;
  public form = new UntypedFormGroup({});

  private subscriptions = new Subscription();
  private areParametersValid = false;
  public showInactive: boolean;

  constructor(
    private setupParametersService: SetupParametersService,
    private qaHistoryDsService: QaHistoryDsService,
    private setupPhaseService: SetupPhaseService,
    private setupFooterService: SetupFooterService,
    private parametersFactoryService: ParametersFactoryService,
    private activeOrderDsService: ActiveOrderDsService,
    private patternInfoDsService: PatternInfoDsService,
    private signatureHandlerService: SignatureHandlerService,
    private appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.setupParametersService.showInactiveElement();
    this.parametersFactoryService.setValuesForSetup();
    this.printInfo$ = this.setupParametersService.printInfo$;
    this.setupParametersService.getOutputUnitPrintLabelInfo();
    const productionOrderId = this.activeOrderDsService.getActiveOrderId();

    this.setCommands();

    this.subscriptions.add(
      combineLatest([
        this.qaHistoryDsService.isQaHistoryAvailableForSetup(),
        this.qaHistoryDsService.isLineClearanceEnableForSetup(),
        this.patternInfoDsService.isPatternAvailableForProductionOrder(productionOrderId)
      ]).subscribe(([isQaHistoryAvailable, isLineClearanceEnable, isPatternAvailable]) => {
        this.updateBackCommand(isQaHistoryAvailable, isLineClearanceEnable, isPatternAvailable);
      })
    );

    this.subscriptions.add(
      this.qaHistoryDsService.isQaChecksAvailableForSetup().subscribe((isAvailable) => {
        this.updateNextCommand(isAvailable);
      })
    );
    this.subscriptions.add(
      this.form.statusChanges.subscribe(() => {
        this.validateNextCommand();
      })
    );
  }

  public ngOnDestroy(): void {
    this.parametersFactoryService.cleanParametersFactoryValues();
  }

  public printLabel(printLabel: PrintLabelForOuterEntry): void {
    this.setupParametersService.printOutputUnitLabel(printLabel);
  }

  public quantityPerOuterChange(outerInfo: OuterInfo): void {
    this.setupParametersService.saveQuantityPerOuter(outerInfo.quantityPerOuter);
  }

  private setCommands(): void {
    this.backCommandService = new PreviousSetupSubphaseCommandService(this.setupFooterService);
    this.nextCommandService = new NextSetupSubphaseCommandService(this.setupFooterService);
   
    this.setupPhaseService.setSetupCommands(this.backCommandService, this.nextCommandService);
  }

  private updateBackCommand(isQaHistoryAvailable: boolean, isLineClearanceAvailable: boolean, isPatternAvailable: boolean): void {
    if (isPatternAvailable) {
      this.backCommandService.setNavigationParams(nav.routes.setupPatternInfo);
      this.backCommandService.enabled = true;
    } else if (isQaHistoryAvailable) {
      this.backCommandService.setNavigationParams(nav.routes.setupQaHistory);
      this.backCommandService.enabled = true;
    } else if (isLineClearanceAvailable) {
      this.backCommandService.setNavigationParams(nav.routes.setupLineClearance);
      this.backCommandService.enabled = true;
      this.setBackCommandExecution();
    } else {
      this.backCommandService.enabled = false;
    }
  }

  private updateNextCommand(isQaAvailable: boolean): void {
    if (isQaAvailable) {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_IN_PROGRESS_FINISHING_CHECK, nav.routes.qualityAssurance);
    } else {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_MATERIAL_WASTE, nav.routes.wasteAssignment);
    }
  }

  public onParameterValidation(isValid: boolean) {
    this.areParametersValid = isValid;
    this.validateNextCommand();
  }

  private validateNextCommand(): void {
    this.nextCommandService.enabled = this.form.valid && this.areParametersValid;
  }

  private setBackCommandExecution() {
    this.backCommandService.execute = () => {
      this.setupFooterService
        .goToNextSubPhase(nav.routes.setupLineClearance, RunPhaseType.SETUP,
          RunSubPhaseType.SETUP_LINE_CLEARANCE, setupPhaseFooterRoutes)
        .pipe(
          finalize(() => {
          })
        )
        .subscribe();
    };
  }
}
