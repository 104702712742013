import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { QaHistoryComponent } from './containers/qa-history/qa-history.component';
import { ArticleDropdownComponent } from '@app/modules/qa-history/containers/qa-history/components/article-dropdown/article-dropdown.component';
import { NcSidebarItemComponent } from '@app/modules/qa-history/containers/qa-history/components/nc-sidebar-item/nc-sidebar-item.component';
import { NcReasonComponent } from './containers/qa-history/components/nc-reason/nc-reason.component';
import { NcMeasureComponent } from './containers/qa-history/components/nc-measure/nc-measure.component';
import { NcInfoComponent } from './containers/qa-history/components/nc-info/nc-info.component';
import { NcDefectiveQuantityComponent } from './containers/qa-history/components/nc-defective-quantity/nc-defective-quantity.component';
import { NcDocumentListComponent } from './containers/qa-history/components/nc-document-list/nc-document-list.component';
import { NcDefectiveItemComponent } from './containers/qa-history/components/nc-defective-item/nc-defective-item.component';
import { NcDefectiveItemsCarouselComponent } from './containers/qa-history/components/nc-defective-items-carousel/nc-defective-items-carousel.component';
import { NcDefectiveItemsModalComponent } from './containers/qa-history/components/nc-defective-items-modal/nc-defective-items-modal.component';
import { NcDocumentItemComponent } from './containers/qa-history/components/nc-document-item/nc-document-item.component';

@NgModule({
  declarations: [
    QaHistoryComponent,
    ArticleDropdownComponent,
    NcSidebarItemComponent,
    NcInfoComponent,
    NcMeasureComponent,
    NcReasonComponent,
    NcDefectiveQuantityComponent,
    NcDocumentListComponent,
    NcDocumentItemComponent,
    NcDefectiveItemComponent,
    NcDefectiveItemsCarouselComponent,
    NcDefectiveItemsModalComponent
  ],
  imports: [SharedModule],
  exports: [QaHistoryComponent, NcSidebarItemComponent]
})
export class QaHistoryModule {}
