<ng-container *ngIf="pallet; then palletQaCheck; else periodicQaCheck"></ng-container>

<ng-template #palletQaCheck>
  <app-qa-check-list 
    [fields]="fields" 
    [form]="form" 
    [materialId]="pallet.producedMaterialId"
    [signatureConfig]="signatureConfig"
  ></app-qa-check-list>
</ng-template>

<ng-template #periodicQaCheck>
  <app-qa-check-list
    [fields]="fields"
    [form]="form"
    [isManualPeriodicQaCheck]="isManualPeriodicQaCheck"
    [isAutomaticPeriodicQaCheck]="isAutomaticPeriodicQaCheck"
    [periodicQaCheckId]="periodicQaCheckId"
    [signatureConfig]="signatureConfig"
  ></app-qa-check-list>
</ng-template>
