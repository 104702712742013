<section class="registration-table">
  <p-table [value]="loggedInData">
    <ng-template pTemplate="header">
      <tr>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.NAME' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ROLE' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.LOGINTIME' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ID' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.REGISTRATION_PURPOSE' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.NOTE' | translate }}</th>
        <th class="registration-table__last-cell"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="registration-table__text-cell">{{ rowData.name }}</td>
        <td>
          <p-dropdown
            [ngModel]="rowData.role"
            [options]="roles"
            [style]="{ width: '200px' }"
            [appendTo]="'body'"
            (onChange)="onRoleChange($event, rowData)"
          />
        </td>
        <td class="registration-table__text-cell">{{ rowData.loginTime | utcToLocal | date : 'dd.MM.YYYY HH:mm' }}</td>
        <td class="registration-table__text-cell">
          {{ rowData.externalEmployeeId }}
        </td>
        <td class="registration-table__text-cell">
          <span>{{ rowData.registrationPurpose | emptyTextPlaceholder }}</span>
        </td>
        <td class="registration-table__text-cell">
          <input
            (blur)="onLoggedInEmpoyeeBlur($event.srcElement.value, rowData)"
            [ngModel]="rowData.note"
            id="print-label-input"
            pInputText
            type="text"
            [maxLength]="250"
          />
        </td>
        <td>
          <lib-button
            (clicked)="onLogOutClick(rowData)"
            [label]="'EMPLOYEEREGISTRATION.LOGOUT' | translate"
            [typeClass]="'button--primary'"
            [sizeClass]="'button--small'"
            [loadingTopic]="LOADING_TOPIC_LOGOUT_ + rowData.id"
          ></lib-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">{{ 'APP.NO_DATA_FOUND' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>
</section>
