import { Injectable } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services';
import { ActiveOrderDataQuery, ActiveOrderDataService } from '@app/core/global-state';
import { HubUrlsService } from '@app/core/utils';
import { HeaderKpiViewModel, WebSocketClientService } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SetupHeaderService {
  constructor(
    private hubUrlsService: HubUrlsService,
    private webSocketClientService: WebSocketClientService,
    private activeOrderDsService: ActiveOrderDsService,
    private activeOrderDataService: ActiveOrderDataService,
    private activeOrderDataQuery: ActiveOrderDataQuery
  ) {}

  public getHeaderKpis(): Observable<HeaderKpiViewModel> {
    this.activeOrderDataService.getHeaderKpi();
    return this.activeOrderDataQuery.headerKpis$;
  }

  public getHeaderKpiNotifications(): Observable<HeaderKpiViewModel> {
    const hubUrl = this.hubUrlsService.getHeaderKpi(this.activeOrderDsService.getActiveOrderId());
    return this.webSocketClientService.getNotificationsForTopic<HeaderKpiViewModel>(hubUrl).pipe(
      tap((data) => {
        this.activeOrderDataService.setHeaderKpi(data);
      })
    );
  }
}
