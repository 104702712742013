<section class="chronos-dialog--size-lg">
  <div class="p-grid p-nogutter kpi">
    <div class="kpi__container">
      <div class="kpi__container-values" *ngIf="automaticPeriodicQaChecks">
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'RUN_PHASE.FREQUENCY_TYPE' | translate"
          [kpiValue]="formatFrequencyType(automaticPeriodicQaChecks.frequencyType) | translate"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'RUN_PHASE.FREQUENCY' | translate"
          [kpiValue]="
            !this.frequencyInMinutes
              ? automaticPeriodicQaChecks.frequency
              : automaticPeriodicQaChecks.frequency + ' ' + ('RUN_PHASE.MINUTE' | translate)
          "
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'RUN_PHASE.TIME_STAMP' | translate"
          [kpiValue]="
            automaticPeriodicQaChecks.creationTime
              ? (automaticPeriodicQaChecks.creationTime | utcToLocal | date : 'EEEE, d MMMM y, HH:mm:ss' : 'locale' : this.defaultLanguage)
              : ''
          "
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          *ngIf="this.frequencyInProcessingUnit && automaticPeriodicQaChecks.creationQuantity"
          class="kpi__item"
          [kpiLabel]="'ACTIVE_ORDER.QUANTITY' | translate"
          [kpiValue]="automaticPeriodicQaChecks.creationQuantity"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
      </div>
    </div>
  </div>
  <app-qa-check-list-in-run
    *ngIf="automaticPeriodicQaChecks"
    [fields]="automaticPeriodicQaChecks.productionQaChecks"
    [periodicQaCheckId]="automaticPeriodicQaChecks.periodicQaCheckId"
    (formValidationChange)="setConfirmButtonStatus($event)"
    [isManualPeriodicQaCheck]="false"
    [isAutomaticPeriodicQaCheck]="true"
    [signatureConfig]="signatureConfig"
  ></app-qa-check-list-in-run>

  <div *ngIf="automaticPeriodicQaChecks">
    <app-signature *ngIf="isSignatureEnabled"
      [productionQaCheck] = "automaticPeriodicQaChecks.productionQaChecks"
      [isRunPhaseMode]= "true"
    ></app-signature>
  </div>

  <lib-modal-footer
    [submitLabel]="'PALLET_CARD.QA_CONFIRM'"
    [declineLabel]="'ACTIVE_ORDER.LATER'"
    (submitClicked)="submitModal()"
    (declineClicked)="later()"
    [submitDisabled]="!isConfirmButtonEnabled"
    [showDeclineButton]="true"
  ></lib-modal-footer>
</section>
