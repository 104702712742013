import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentItem } from 'chronos-core-client';

@Component({
  selector: 'app-nc-document-item',
  templateUrl: './nc-document-item.component.html',
  styleUrls: ['./nc-document-item.component.scss']
})
export class NcDocumentItemComponent {
  @Input() document: DocumentItem;
  @Output() openDocument = new EventEmitter<DocumentItem>();

  private readonly pdfExtension = 'pdf';
  component: { extension: string };

  public isDocumentPDF(document: DocumentItem): boolean {
    return document.extension === this.pdfExtension;
  }

  public openDocumentClicked(document: any): void {
    this.openDocument.emit(document);
  }
}
