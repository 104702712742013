<section *ngIf="taskQuery.allTasks$ | async as taskList" class="task-list page-padding">
  <header class="task-list__header">
    <h1 class="page-header" [title]="taskList.length">{{ 'TASK_LIST.TITLE' | translate }}</h1>

    <section class="filters">
      <div class="form-group">
        <p-selectButton
          [options]="optionsDowntimeCodeAssignation"
          [(ngModel)]="selectedDowntimeCodeAssignation"
          optionLabel="icon"
          (onChange)="onFilterChange()"
        >
          <ng-template let-item pTemplate>
            <ng-container *ngIf="item.icon">
              <span>
                <i [ngClass]="item.icon"></i>
              </span>
            </ng-container>
            <ng-container *ngIf="item.label">
              <span>{{ item.label | translate }}</span>
            </ng-container>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="form-group">
        <span class="col-form-label">
          {{ 'TASK_LIST.TYPE' | translate }}
        </span>
        <p-dropdown
          class="filters__input"
          [options]="typeOptions$ | async"
          [(ngModel)]="selectedType"
          (onChange)="onFilterChange()"
          [virtualScroll]="true"
          [virtualScrollItemSize]="2"
          [panelStyle]="{ 'max-height': '70px', 'min-width': 'min(100vw, 200px)' }"
        ></p-dropdown>
      </div>

      <div class="form-group">
        <span class="col-form-label">
          {{ 'TASK_LIST.PRODUCTION_ORDER' | translate }}
        </span>

        <p-dropdown
          class="filters__input"
          [options]="productionOrderOptions$ | async"
          [(ngModel)]="selectedProductionOrder"
          (onChange)="onFilterChange()"
          [virtualScroll]="true"
          [virtualScrollItemSize]="1"
        ></p-dropdown>
      </div>

      <div class="form-group text-search p-input-icon-left">
        <i class="container-search-bar__search icon icon-search"></i>
        <input
          id="textSearch"
          type="text"
          class="search-bar"
          pInputText
          [(ngModel)]="textQuery"
          [placeholder]="'TASK_LIST.SEARCH_BAR_PLACEHOLDER' | translate"
          (input)="onFilterChange()"
          style="max-height: 35px"
        />
      </div>

      <div class="form-control">
        <lib-button [label]="'TASK_LIST.RESET'" (clicked)="resetFilters()"></lib-button>
      </div>
    </section>
  </header>
  <div>
    <lib-machine-chart
      [workCenterId]="workCenterId$ | async"
      [(task)]="task"
      [taskList]="taskList"
      [start]="'MACHINE_CHART.START' | translate"
      [rest]="'MACHINE_CHART.REST' | translate"
      [chartMode]="chartMode"
    ></lib-machine-chart>
  </div>
  <div *ngIf="downtimeViewData$ | async as downtimeViewData; else loading">
    <div *ngIf="(this.taskQuery.isLoading$ | async) === false; else loading">
      <app-task-item
        *ngFor="let taskItem of taskList; trackBy: trackByFunction"
        [taskItem]="taskItem"
        [taskList]="taskList"
        [downtimeViewData]="downtimeViewData"
        [isHighlightTask]="taskItem === task"
        (itemSelected)="onItemSelected($event)"
        (itemChecked)="onItemChecked($event)"
      ></app-task-item>
    </div>
  </div>

  <ng-template #loading>
    <lib-loading-indicator></lib-loading-indicator>
  </ng-template>
</section>
