import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentItem } from 'chronos-core-client';

@Component({
  selector: 'app-nc-document-list',
  templateUrl: './nc-document-list.component.html',
  styleUrls: ['./nc-document-list.component.scss']
})
export class NcDocumentListComponent {
  @Input() public nonConformanceDocuments: DocumentItem[];
  @Input() public isFullscreen = false;
  @Input() public isImageClickable = false;
  @Input() public isCreateNCMode: boolean;

  public areDocumentsAvailable(): boolean {
    return this.isCreateNCMode ? false : this.nonConformanceDocuments?.length > 0;
  }

  public openDocument(document: DocumentItem): void {
    window.open(document.downloadLink, '_blank');
  }
}
