<div *ngIf="!isDisplayChart && (chartMode === ChartMode.SplitDowntime || chartMode === ChartMode.EditDowntime)">
  {{ 'MACHINE_CHART.SPLIT_DOWNTIME_MESSAGE' | translate }}
</div>

<div *ngIf="isDisplayChart">
  <!-- Header -->
  <div class="adjust_info" style="color: blue" *ngIf="!!editedExternalOrderId">
    {{ editedExternalOrderId }}
  </div>
  <div class="p-grid adjust_info">
    <div
      class="p-col-10"
      *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove || chartMode === ChartMode.EditDowntime"
    >
      <span *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove">
        {{ 'MACHINE_CHART.EDIT_SETUP_HEADER' | translate }}
      </span>
      <span *ngIf="chartMode === ChartMode.EditDowntime">
        {{ 'MACHINE_CHART.EDIT_DOWNTIME_SETUP_HEADER' | translate }}
      </span>
    </div>
  </div>
  <br />

  <!-- Header input fields -->
  <form [formGroup]="formGroup" *ngIf="chartMode === ChartMode.EditSetup">
    <div class="header-line-container" *ngIf="!!orderSetupPhaseState; else loader">
      <div class="header-line-item header-line-item__start">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }} &nbsp;</span>
        <lib-calendar
          [showTime]="false"
          [ngModel]="startTime"
          (ngModelChange)="startTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [disabled]="false"
        ></lib-calendar>
        &nbsp;
        <app-time-input
          [showControls]="false"
          [ngModel]="startTime"
          (ngModelChange)="startTimeChanged($event, false)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [disabled]="false"
        ></app-time-input>
      </div>

      <div class="header-line-item header-line-item__middle">
        <span class="header-line-item__label">{{ 'MACHINE_CHART.SETUP_QUANTITY' | translate }} &nbsp;</span>

        <lib-input-number
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="quantity.value"
          [min]="0"
          [size]="5"
          (focusout)="setupQuantityChanged()"
          formControlName="setupQuantity"
          [inputStyle]="INPUT_STYLE"
          [unit]="unitId"
        ></lib-input-number>
      </div>

      <div class="header-line-item header-line-item__end">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }} &nbsp;</span>
        <lib-calendar
          [showTime]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
        &nbsp;
        <app-time-input
          [showControls]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, false)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></app-time-input>
      </div>
    </div>
  </form>

  <form [formGroup]="formGroup" *ngIf="chartMode === ChartMode.EditSetupApprove">
    <div class="header-line-container" *ngIf="!!orderSetupPhaseState; else loader">
      <div class="header-line-item header-line-item__start">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }} &nbsp;</span>
        <lib-calendar
          [showTime]="false"
          [ngModel]="startTime"
          (ngModelChange)="startTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [disabled]="false"
        ></lib-calendar>
        &nbsp;
        <app-time-input
          [showControls]="false"
          [ngModel]="startTime"
          (ngModelChange)="startTimeChanged($event, false)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [disabled]="false"
        ></app-time-input>
      </div>

      <div class="header-line-item header-line-item__middle">
        <div class="header-line-item header-group">
          <span class="header-line-item__label">{{ 'MACHINE_CHART.SETUP_QUANTITY' | translate }} &nbsp;</span>
          <lib-input-number
            class="edit_setup-phase-duration__col-form-setup-value setup-value"
            [(ngModel)]="quantity.value"
            [min]="0"
            [size]="5"
            (focusout)="setupQuantityChanged()"
            formControlName="setupQuantity"
            [inputStyle]="INPUT_STYLE"
            [unit]="unitId"
          ></lib-input-number>
        </div>

        <div class="header-line-item header-group">
          <span class="header-line-item__label">{{ 'MACHINE_CHART.WASTE' | translate }} &nbsp;</span>
          <lib-input-number
            class="edit_setup-phase-duration__col-form-setup-value setup-value"
            [(ngModel)]="waste.value"
            [min]="0"
            [size]="5"
            [max]="quantity"
            (focusout)="onWasteChanged()"
            formControlName="waste"
            [inputStyle]="INPUT_STYLE"
            [unit]="unitId"
          ></lib-input-number>
        </div>

        <div class="header-line-item header-group">
          <span class="header-line-item__label">{{ 'MACHINE_CHART.MACULATURE' | translate }} &nbsp;</span>
          <lib-input-number
            class="edit_setup-phase-duration__col-form-setup-value setup-value"
            [(ngModel)]="maculature.value"
            [min]="0"
            [size]="5"
            [max]="quantity"
            (focusout)="onMaculatureChanged()"
            formControlName="maculature"
            [inputStyle]="INPUT_STYLE"
            [unit]="unitId"
          ></lib-input-number>
        </div>
      </div>

      <div class="header-line-item header-line-item__end">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }} &nbsp;</span>
        <lib-calendar
          [showTime]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
        &nbsp;
        <app-time-input
          class="split_time"
          [showControls]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, false)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></app-time-input>
      </div>
    </div>
  </form>

  <form [formGroup]="formGroup" *ngIf="chartMode === ChartMode.SplitDowntime">
    <div class="header-line-container">
      <div class="header-line-item header-line-item__start">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }} &nbsp;</span>
        <span class="header-line-item__value">
          {{ startTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
        </span>
      </div>

      <div class="header-line-item header-line-item__middle">
        <span class="header-line-item__label">{{ 'MACHINE_CHART.SPLIT_TIME' | translate }} &nbsp;</span>
        <lib-calendar
          [showTime]="false"
          [ngModel]="splitTime"
          (ngModelChange)="splitTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
        &nbsp;
        <app-time-input
          [showControls]="false"
          [ngModel]="splitTime"
          (ngModelChange)="splitTimeChanged($event, false)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></app-time-input>
      </div>

      <div class="header-line-item header-line-item__end">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }} &nbsp;</span>
        <span class="header-line-item__value">
          {{ endTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
        </span>
      </div>
    </div>
  </form>

  <form [formGroup]="formGroup" *ngIf="chartMode === ChartMode.EditDowntime">
    <div class="header-line-container">
      <div class="header-line-item header-line-item__start">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }} &nbsp;</span>
        <span class="header-line-item__value">
          {{ startTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
        </span>
      </div>

      <div class="header-line-item header-line-item__middle">
        <span class="header-line-item__label">{{ 'MACHINE_CHART.EDIT_DOWNTIME_WASTE' | translate }} &nbsp;</span>
        <p-inputNumber
          [(ngModel)]="quantity.value"
          [min]="0"
          [size]="5"
          [max]="quantity"
          (focusout)="setupQuantityChanged()"
          formControlName="setupQuantity"
          [locale]="locale"
          [minFractionDigits]="minFractionDigits"
          [maxFractionDigits]="maxFractionDigits"
          [inputStyle]="INPUT_STYLE"
        ></p-inputNumber>
      </div>

      <div class="header-line-item header-line-item__end">
        <span class="header-line-item__label">{{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }} &nbsp;</span>
        <lib-calendar
          [showTime]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
        &nbsp;
        <app-time-input
          class="split_time"
          [showControls]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, false)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></app-time-input>
      </div>
    </div>
  </form>

  <!-- Chart -->
  <ng-container *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove; else otherChart">
    <lib-machine-chart
      *ngIf="!!orderSetupPhaseState"
      [workCenterId]="workCenterId"
      [isMachineChartInSetupPhase]="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove"
      [setupStartTime]="startTime"
      [setupEndTime]="endTime"
      [splitTime]="splitTime"
      [isChartIsInSplitDowntime]="false"
      [earlistDateLimit]="earliestLimit"
      [latestDateLimit]="latestLimit"
      [orderSetupPhaseState]="orderSetupPhaseState"
      (setupStartTimeChanged)="startTimeChanged($event, false)"
      (splitTimeChanged)="splitTimeChanged($event, false)"
      (setupEndTimeChanged)="endTimeChanged($event, false)"
      [chartMode]="chartName"
    ></lib-machine-chart>
  </ng-container>

  <ng-template #otherChart>
    <lib-machine-chart
      [workCenterId]="workCenterId"
      [isMachineChartInSetupPhase]="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove"
      [setupStartTime]="startTime"
      [setupEndTime]="endTime"
      [splitTime]="splitTime"
      [isChartIsInSplitDowntime]="chartMode === ChartMode.SplitDowntime"
      [earlistDateLimit]="earliestLimit"
      [latestDateLimit]="latestLimit"
      (setupStartTimeChanged)="startTimeChanged($event, false)"
      (splitTimeChanged)="splitTimeChanged($event, false)"
      (setupEndTimeChanged)="endTimeChanged($event, false)"
      [chartMode]="chartName"
    ></lib-machine-chart>
  </ng-template>
</div>

<ng-template #loader>
  <div class="loader">
    <lib-loading-indicator></lib-loading-indicator>
  </div>
</ng-template>

<lib-modal-footer
  [submitLabel]="'MODAL_FOOTER.CONFIRM'"
  (submitClicked)="onSubmit()"
  (declineClicked)="onDecline()"
  (resetClicked)="editDowntimeReset()"
  [submitDisabled]="submitDisabled"
  [whenLoadingTopic]="LOADING_TOPIC"
  [resetButtonLoadingTopic]="LOADING_TOPIC_EDITDOWNTIME"
  [isResetVisible]="chartMode === ChartMode.EditDowntime"
></lib-modal-footer>
