<input
  #scanInput
  type="text"
  [(ngModel)]="scanInputText"
  class="scan-clearance-input"
  (input)="onInput($event.target.value)"
  (keyup.enter)="onEnter($event.target.value)"
  pInputText
/>

<div *ngIf="isReadOnly" class="readonly-message">
  {{ 'SIGNATURE.READONLY_MESSAGE' | translate }}
 </div>

<div class="p-grid" (click)="onClick($event)" [ngClass]="{'readonly-mode': isReadOnly}">
  <div class="p-col" *ngIf="fields$ | async as fields">
    <p class="scan-warning-text" *ngIf="isCapsLockOn">{{ 'SETUP_LINE_CLEARANCE.CAPSLOCK_ON_WARNING' | translate }}</p>
    <app-line-clearance-list-in-setup [fields]="fields" (formValid)="onFormValidityChange($event)"></app-line-clearance-list-in-setup>
  </div>
</div>

<div *ngIf="shouldShowSignatureContainer()" class="signature-container" [ngClass]="{'readonly-mode': isReadOnly}">
  <ng-container *ngFor="let position of ['left-signature', 'right-signature']">
    <app-signature
      [ngClass]="getSignatureClasses(position)"
      [productionQaCheck]="position === 'left-signature' ? leftSignatureQaChecks : rightSignatureQaChecks"
      [signatureConfig]="signatureConfig"
      (click)="setActiveSignature(position)">
    </app-signature>
  </ng-container>
</div>



