<section class="modal-dialog-box">
  <div *ngIf="this.tabName">
    <div class="material-dropdown" *ngIf="!preselectedMaterialFilter">
      <label class="material-dropdown__label">{{ 'MOUNTING.MATERIAL' | translate }}</label>
      <lib-dropdown
        [inlineStyle]="{ width: '350px' }"
        [options]="dropDownOptions"
        [(ngModel)]="selectedOption"
        (ngModelChange)="filterWarehouseData()"
        [disabled]="isDropDownOptionDisabled"
      ></lib-dropdown>
    </div>

    <div class="material-table">
      <app-material-table
        [tableColumns]="columns"
        [tableData]="warehouseFilteredData"
        [selectedRow]="selectedMaterial"
        (selectedRowChanged)="selectedMaterialChange($event)"
        [highlightAllRows]="consumeAllRows"
      ></app-material-table>
    </div>

    <div *ngIf="isMaterialQuantityRequired && isQuantityShown" class="consume-quantity">
      <div *ngIf="isConsumeAllEnabled" class="consume-quantity__consume-all">
        <p-checkbox
          [(ngModel)]="consumeAllRows"
          (ngModelChange)="consumeAllCheckChange()"
          binary="true"
          [label]="'MOUNTING.CONSUME_ALL' | translate"
        ></p-checkbox>
      </div>
      <div class="consume-quantity__wrapper">
        <span class="consume-quantity__label">{{ 'MOUNTING.QUANTITY' | translate }}</span>
        <lib-input-number
          [(ngModel)]="selectedMaterialQuantity"
          [inputStyle]="{ width: '80px' }"
          [maxFractionDigits]="2"
          [minFractionDigits]="2"
          (touched)="onBlurInputQuantity()"
          [disabled]="consumeAllRows"
        ></lib-input-number>
        <span class="consume-quantity__label">
          <span *ngIf="selectedMaterialQuantityUnitId; else elseEmpty">{{ selectedMaterialQuantityUnitId }}</span>
          <ng-template #elseEmpty>‒</ng-template>
        </span>
      </div>
    </div>
  </div>
</section>

<lib-modal-footer
  [submitLabel]="submitButtonLabel"
  [showDeclineButton]="showDecline"
  (declineClicked)="onCloseModal()"
  (submitClicked)="onSubmitClick()"
  [submitDisabled]="isSubmitDisabled()"
  [whenLoadingTopic]="LOADING_TOPIC"
></lib-modal-footer>
