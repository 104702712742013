import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QaCheckField } from '@app/shared/models';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-qa-check-element',
  templateUrl: './qa-check-element.component.html',
  styleUrls: ['./qa-check-element.component.scss']
})
export class QaCheckElementComponent {
  @Input() public parentForm: UntypedFormGroup;
  @Input() public field: QaCheckField;
  @Input() public isReadOnly?: false;

  public isCollapsed = true;
  public readonly INPUT_STYLE = { width: '8em', textAlign: 'end' };
  public unit: string;
  public toggleContent(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  public isMeasurementUnitVisible(): boolean {
    return !this.field.isLineClearanceCheck && !!this.field.measurementResultUnitId && this.field.measurementResultUnitId.length > 0;
  }
}
