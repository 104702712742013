<div (window:resize)="onResize($event)" #chartContainer>
  <div *ngIf="workCenterId; else NoWorkCenter">
    <div *ngIf="chartDetails; else loading">
      <highcharts-chart
        #chartContainer
        [Highcharts]="highcharts"
        [options]="chartDetails"
        [(update)]="updateFromInput"
        [oneToOne]="true"
        [constructorType]="chartConstructor"
        [callbackFunction]="chartCallback"
        class="fullScreen"
        (click)="onPointSelect($event)"
      ></highcharts-chart>
    </div>
  </div>

  <ng-template #loading>
    <div class="spinner">
      <i class="pi pi-spin pi-spinner"></i>
      &nbsp;{{ 'MACHINE_CHART.LOADING' | translate }}
    </div>
  </ng-template>

  <ng-template #NoWorkCenter>
    <div *ngIf="noWorkCenterAccessFlag" class="noworkcentertext">
      {{ 'MACHINE_CHART.NO_WORKCENTER_ACCESS_MESSAGE' | translate }}
    </div>
    <div *ngIf="noWorkCenterSelectedFlag" class="noworkcentertext">
      {{ 'MACHINE_CHART.WORKCENTER_NOTSELECTED_MESSAGE' | translate }}
    </div>
  </ng-template>

  <div *ngIf="isFullViewMode && !isMobileView">
    <br />
    <form [formGroup]="formGroup">
      <div class="parent-filter-div">
        <div class="filter-options-parent">
          <span class="filter-text">
            <b>{{ 'MACHINE_CHART.FILTER' | translate }}</b>
          </span>
          <button (click)="toggleFilters()" class="pi pi-angle-double-{{ sign }} btnFilter"></button>
        </div>
        <div *ngIf="!isCollapsed" class="p-grid p-fluid filter-options">
          <div class="p-col-12 p-md-12 p-lg-2">
            <label>{{ 'MACHINE_CHART.START' | translate }}:</label>
            <p-calendar
              [showIcon]="true"
              [ngModel]="periodStart"
              [ngModelOptions]="{ standalone: true, updateOn: 'blur' }"
              dateFormat="dd.mm.yy"
              hourFormat="24"
              showTime="true"
              appendTo="body"
              utc="true"
              [keepInvalid]="true"
              (onSelect)="onStartDateSelect($event)"
              [maxDate]="maxDate"
              [selectOtherMonths]="true"
            ></p-calendar>
            <span *ngIf="!isDurationValid" class="date-validation-error">{{ 'MACHINE_CHART.TIME_RANGE_TOO_LARGE' | translate }}</span>
          </div>
          <div class="p-col-12 p-md-12 p-lg-2">
            <label (click)="showEndDate()" [ngClass]="{ 'link-text': showEndDatetime === false }">
              {{ 'MACHINE_CHART.END' | translate }}:
            </label>
            <label>/</label>
            <label (click)="showPeriodInfo()" [ngClass]="{ 'link-text': showPeriod === false }">
              {{ 'MACHINE_CHART.PERIOD' | translate }}:
            </label>
            <p-calendar
              *ngIf="showEndDatetime"
              [showIcon]="true"
              [ngModel]="periodEnd"
              [ngModelOptions]="{ standalone: true, updateOn: 'blur' }"
              dateFormat="dd.mm.yy"
              hourFormat="24"
              showTime="true"
              appendTo="body"
              utc="true"
              [keepInvalid]="true"
              (onSelect)="onEndDateSelect($event)"
              [maxDate]="maxDate"
              [selectOtherMonths]="true"
            ></p-calendar>
            <span *ngIf="!isDateValid" class="date-validation-error">{{ 'MACHINE_CHART.DATE_VALIDATION_ERROR' | translate }}</span>
            <p-dropdown
              appendTo="body"
              *ngIf="showPeriod"
              [options]="timeFilters"
              formControlName="period"
              (onChange)="onPeriodSelect($event)"
            ></p-dropdown>
          </div>
          <div
            class="p-col-4 p-md-4 p-lg-2"
            [ngClass]="{ 'align-filter-buttons': isDetailedView === false, 'align-filter-buttons-detailed-view': isDetailedView === true }"
          >
            <button pButton (click)="onReset()" class="filter-Button" [label]="'MACHINE_CHART.RESET' | translate"></button>
          </div>
          <div
            class="p-col-2 p-md-2 p-lg-1"
            [ngClass]="{ 'align-filter-live': isDetailedView === false, 'align-filter-live-detailed-view': isDetailedView === true }"
          >
            <p-checkbox
              (onChange)="onLiveChange()"
              [(ngModel)]="isLiveviewEnabled"
              [label]="'MACHINE_CHART.LIVE_VIEW' | translate"
              [ngModelOptions]="{ standalone: true }"
              binary="true"
            ></p-checkbox>
          </div>
          <div
            class="p-col-4 p-md-4 p-lg-1"
            [ngClass]="{ 'align-filter-buttons': isDetailedView === false, 'align-filter-buttons-detailed-view': isDetailedView === true }"
          >
            <button
              pButton
              type="submit"
              (click)="onBackClick()"
              [disabled]="isChartZoomed"
              class="back-forward-Button"
              [label]="'MACHINE_CHART.BACK' | translate"
            ></button>
          </div>
          <div
            class="p-col-4 p-md-4 p-lg-1"
            [ngClass]="{ 'align-filter-buttons': isDetailedView === false, 'align-filter-buttons-detailed-view': isDetailedView === true }"
          >
            <button
              pButton
              type="submit"
              (click)="onForwardClick()"
              [disabled]="isChartZoomed || isForwardDisabled || isLiveviewEnabled"
              class="back-forward-Button"
              [label]="'MACHINE_CHART.FORWARD' | translate"
            ></button>
          </div>
          <div
            class="p-col-12 p-md-12 p-lg-2"
            *ngIf="showMachineList"
            [ngClass]="{ 'align-machineList': isDetailedView === false, 'align-machineList-detailed-view': isDetailedView === true }"
          >
            <mat-form-field>
              <mat-label>{{ 'MACHINE_CHART.SELECT_MACHINE' | translate }}</mat-label>
              <mat-select hideSingleSelectionIndicator (selectionChange)="onMachineSelect($event)" formControlName="machineId">
                <mat-option *ngFor="let machine of machineList" [value]="machine.id">
                  {{ machine.externalWorkCenterId }}-{{ machine.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="p-col-2 p-md-2 p-lg-1"
            [ngClass]="{ 'align-speed-buttons': isDetailedView === false, 'align-speed-buttons-detailed-view': isDetailedView === true }"
          >
            <button mat-icon-button class="icon-buttons" color="primary" (click)="openSpeedMonitor()">
              <mat-icon>tv</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="(isFullViewMode && isDetailedView && isMobileView) || (isFullViewMode && !isDetailedView && isMobileView)">
    <br />
    <form [formGroup]="formGroup">
      <div class="parent-filter-div">
        <div class="filter-options-parent">
          <span class="filter-text">
            <b>{{ 'MACHINE_CHART.FILTER' | translate }}</b>
          </span>
          <button (click)="toggleFilters()" class="pi pi-angle-double-{{ sign }} btnFilter"></button>
        </div>
        <div *ngIf="!isCollapsed" class="p-grid p-fluid filter-options">
          <div class="p-col-12 p-md-12 p-lg-4" *ngIf="showMachineList">
            <mat-form-field>
              <mat-label>{{ 'MACHINE_CHART.SELECT_MACHINE' | translate }}</mat-label>
              <mat-select (selectionChange)="onMachineSelect($event)" formControlName="machineId">
                <mat-option *ngFor="let machine of machineList" [value]="machine.id">
                  {{ machine.externalWorkCenterId }}-{{ machine.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="p-col-6 p-md-6 p-lg-2">
            <button
              pButton
              type="submit"
              (click)="onBackClick()"
              class="back-forward-Button"
              [label]="'MACHINE_CHART.BACK' | translate"
              icon="pi pi-angle-double-left"
            ></button>
          </div>
          <div class="p-col-6 p-md-6 p-lg-2">
            <button
              pButton
              type="submit"
              (click)="onForwardClick()"
              class="back-forward-Button"
              [label]="'MACHINE_CHART.FORWARD' | translate"
              icon="pi pi-angle-double-right"
              [disabled]="isForwardDisabled"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isDetailedView">
    <br />
  </div>
  <div *ngIf="isDetailedView" class="machine-chart-list">
    <br />
    <div class="to-right">
      <button
        pButton
        type="submit"
        (click)="backToDashboard()"
        class="go-to-dashboard"
        [label]="'MACHINE_CHART.BACK_TO_DASHBOARD' | translate"
        icon="pi pi-angle-double-left"
      ></button>
    </div>
    <mat-table *ngIf="!isMobileView" [dataSource]="dataSource" matSort class="machine-list-border" cdkDropList [cdkDropListDisabled]="true">
      <ng-container matColumnDef="activeProductionOrderCode">
        <mat-header-cell *matHeaderCellDef>
          <span class="header-text">{{ 'MACHINE_LIST.JOB' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.activeProductionOrderCode }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <b>{{ 'MACHINE_LIST.PRODUCTION' | translate }}</b>
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="salesOrderAndPosition">
        <mat-header-cell *matHeaderCellDef>
          <span class="header-text">{{ 'MACHINE_LIST.SALES_ORDER_AND_POSITION' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.salesOrderAndPosition }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="externalFinishedGoodArticleId">
        <mat-header-cell *matHeaderCellDef>
          <span class="header-text">{{ 'MACHINE_LIST.FG' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.externalFinishedGoodArticleId }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="finishedGoodArticleConfigurationId">
        <mat-header-cell *matHeaderCellDef>
          <span class="header-text">{{ 'MACHINE_LIST.CONFIGURATION' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.finishedGoodArticleConfigurationId }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <mat-header-cell *matHeaderCellDef>
          <span class="header-text">{{ 'MACHINE_LIST.CUSTOMER_NAME' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.customerName }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="finishedGoodArticleName">
        <mat-header-cell *matHeaderCellDef>
          <span class="header-text">
            {{ 'MACHINE_LIST.ARTICLE_NAME' | translate }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.finishedGoodArticleName }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="plannedQuantity">
        <mat-header-cell *matHeaderCellDef class="align-values">
          <span class="header-text">
            {{ 'MACHINE_LIST.PLANNED_QUANTITY' | translate }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="align-values">
          {{ element.plannedQuantity | formatQuantity : true }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="quantityPredecessor">
        <mat-header-cell *matHeaderCellDef class="align-values">
          <span class="header-text">
            {{ 'MACHINE_LIST.QUANTITY_PREDECESSOR' | translate }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="align-values">
          {{ element.quantityPredecessor | formatQuantity : true }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="goodQuantity">
        <mat-header-cell *matHeaderCellDef class="align-values">
          <span class="header-text">
            {{ 'MACHINE_LIST.NET_QUANTITY' | translate }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="align-values">
          {{ element.goodQuantity | formatQuantity : true }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="align-values">
          <b>{{ this.netQuantity | number }}</b>
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="waste">
        <mat-header-cell *matHeaderCellDef class="align-values">
          <span class="header-text">{{ 'MACHINE_LIST.WASTE' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="align-values">
          {{ element.waste | formatQuantity : true }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="align-values">
          <b>{{ this.totalWaste | number }}</b>
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="maculature">
        <mat-header-cell *matHeaderCellDef class="align-values">
          <span class="header-text">{{ 'MACHINE_LIST.MACULATURE' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="align-values">
          {{ element.maculature | formatQuantity : true }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="align-values">
          <b>{{ this.totalMaculature | number }}</b>
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="numberOfStoppers">
        <mat-header-cell *matHeaderCellDef class="align-values">
          <span class="header-text">{{ 'MACHINE_LIST.NUMBEROFSTOPPERS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="align-values">
          {{ element.numberOfStoppers.value }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="align-values">
          <b>
            <span>{{ 'MACHINE_LIST.GROSS_QUANTITY' | translate }}</span>
            <span>&nbsp;&nbsp;</span>
            <span>{{ this.grossQuantity | number }}</span>
          </b>
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </mat-table>
  </div>
</div>
<br />
