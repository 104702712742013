<div [formGroup]="parentForm" class="row line-clearance-item">
  <div (click)="toggleContent()" class="col-5 line-clearance-item__item">
    <i
      [ngClass]="{
        'icon-arrow-right-filled-open': isCollapsed,
        'icon-arrows-right-filled-close': !isCollapsed
      }"
      class="collapse-icon"
    ></i>
    <span class="progress__item-title">{{ field.label }}</span>
  </div>
  <div class="col-sm-auto line-clearance-item__item result-component">
    <app-line-clearance-select-button
      formControlName="check"
      (naButtonClicked)="validateModel($event)"
      [name]="field.label"
    ></app-line-clearance-select-button>
  </div>
  <div class="col line-clearance-item__item" [ngClass]="{ 'line-clearance-item__info--required': isInfoRequired && this.isInValid }">
    <input
      #infoInput
      class="line-clearance-item__info-field"
      type="text"
      (blur)="onInfoInputBlur()"
      formControlName="comment"
      (input)="onInfoInputClick($event.target.value)"
      pInputText
    />
  </div>
</div>
<div *ngIf="!isCollapsed" class="row line-clearance-item">
  <div class="col-12 line-clearance-item__item line-clearance-item__item--expanded">{{ field.description }}</div>
</div>
