import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpeedMonitorViewMode } from '../../models/speed-monitor.model';

@Component({
  selector: 'lib-disconnected-mode',
  templateUrl: './disconnected-mode.component.html',
  styleUrls: ['./disconnected-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})
export class DisconnectedModeComponent implements OnChanges {
  @Input() public lastEventTime: Date;
  @Input() public mode: SpeedMonitorViewMode = SpeedMonitorViewMode.Large;

  public tooltipText: string;

  constructor(private translate: TranslateService, private datePipe: DatePipe) {}

  public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.lastEventTime && changes.lastEventTime.currentValue !== changes.lastEventTime.previousValue) {
      const formattedDate = this.datePipe.transform(changes.lastEventTime.currentValue, 'medium');
      this.tooltipText = this.translate.instant('SPEED_MONITOR.DISCONNECTED_TOOLTIP', { lastEventTime: formattedDate });
    }
  }
}
