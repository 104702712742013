<lib-input-number
  [inputId]="inputId"
  [size]="5"
  [(ngModel)]="bobbinQuantity"
  [disabled]="disabled"
  (ngModelChange)="onBobbinQuantityChange($event)"
  (touched)="onBobbinTouched()"
  [required]="required"
  [unit]="unit | uppercase"
  [showUnit]="false"
></lib-input-number>
<span class="pallet-estimate">
  <span>{{ unit }}</span>
  <span *ngIf="bobbinQuantity">
    {{ 'SETUP_PARAMS.BOBBIN_ESTIMATE' | translate : { bobbinPerPallet: getBobbinPerPallet(), estimatedBobbins: getBobbinEstimation() } }}
  </span>
</span>
