<div *ngIf="timeValue$ | async as timeValue" class="time-define">
  <div *ngIf="showControls" class="time-define__change-time-button">
    <button pButton type="button" icon="pi pi-chevron-left" (click)="decreaseTimeByMinute(timeValue)"></button>
  </div>

  <div class="time-define__time">
    <lib-input-number
      #hours
      [format]="false"
      [maxlength]="2"
      [size]="2"
      [min]="0"
      [max]="23"
      [ngModel]="timeValue | date : 'HH'"
      (touched)="setTimeValue(timeValue, hours.value, 'h'); onInputBlur()"
      [inputStyle]="getTimeInputStyles(timeValue)"
      [disabled]="disabled"
      [showUnit]="false"
    ></lib-input-number>
  </div>

  <span class="time-define__separator">:</span>

  <div class="time-define__time">
    <lib-input-number
      #minutes
      [format]="false"
      [maxlength]="2"
      [size]="2"
      [min]="0"
      [max]="59"
      [ngModel]="timeValue | date : 'mm'"
      (touched)="setTimeValue(timeValue, minutes.value, 'm'); onInputBlur()"
      [inputStyle]="getTimeInputStyles(timeValue)"
      [disabled]="disabled"
      [showUnit]="false"
    ></lib-input-number>
  </div>
  <div *ngIf="showControls" class="time-define__change-time-button">
    <button pButton type="button" icon="pi pi-chevron-right" (click)="increaseTimeByMinute(timeValue)"></button>
  </div>
</div>
