<li
  class="progress-item"
  [ngClass]="{
    'progress-item--active': isActive && !isReadOnly,
    'progress-item--active-phase': isPhaseActive && !isReadOnly,
    'progress-item--active-NoSubItems': !hasSubItems && isActive && !isReadOnly,
    'progress-item--read-only': isReadOnly
  }"
  [routerLink]="isReadOnly ? null : link"
>
  <i class="progress-item__icon" [ngClass]="icon" aria-hidden="true"></i>
  <span class="progress-item__label">{{ title }}</span>
</li>
<ul *ngIf="isActive && hasSubItems" class="sub-progress">
  <ng-content></ng-content>
</ul>
