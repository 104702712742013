import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UiStateQuery, UiStateService } from '@app/core/global-state';
import { QaCheckService } from '@app/core/services';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  OutputChangeBobbinQuantity,
  OutputChangeQuantity,
  OutputFinish,
  OutputFinishCheck,
  OutputFinishState,
  OutputLastMaterial
} from '@app/modules/run-phase/models';
import { OutputContainerService } from '@app/modules/run-phase/services';
import { OutputPalletsQuery } from '@app/modules/run-phase/state';
import { ButtonItem } from '@app/shared/components';
import {
  LAST_FLAG_STATUS,
  ProducedMaterialCheckStatus,
  producedMaterialStatusFlags,
  StatusParameter,
  WasteAssignmentFormEvent,
  palletQualtityStatusFlags,
  OuterInfo,
  PalletPrintInfo
} from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import {
  LabelPrintResult,
  OuterPrintInfo,
  ProducedMaterial,
  ProducedMaterialCheckResult,
  ProducedMaterialCheckState,
  ProducedMaterialStatus,
  Quantity,
  OuterLabelType
} from 'chronos-core-client';
import { ProducedMaterialDsService } from '@app/core/data-services';
import { AppSettingsQuery, ListValue, LoadingNotificationService, LogService, ModalConfirmComponent } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { QaChecksModalComponent } from '../qa-checks-modal/qa-checks-modal.component';
import { notificationTopic } from '@app/shared/utils';
import { filter, finalize, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';
import { DocumentsService } from '@app/modules/documents/services';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';

@Component({
  selector: 'app-pallet',
  templateUrl: './pallet.component.html',
  styleUrls: ['./pallet.component.scss']
})
export class PalletComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public outputPallet: ProducedMaterial;
  @Input() public reasonOptions: ListValue[];
  @Input() public isExpanded = false;
  @Input() public manualMode = false;
  @Input() public grossQuantity: Quantity = {
    value: 0
  };
  @Input() public slitCount? = 0;
  @Output() public finishedCheck = new EventEmitter<OutputFinishCheck>();
  @Output() public finishClicked = new EventEmitter<OutputFinish>();
  @Output() public changeContainerQuantityClicked = new EventEmitter<OutputChangeQuantity>();
  @Output() public lastClicked = new EventEmitter<OutputLastMaterial>();
  @Output() public changePalletBobbinQuantityClicked = new EventEmitter<OutputChangeBobbinQuantity>();
  @Output() public labelPrintBobbinButtonClicked = new EventEmitter<BobbinPrintLabel>();
  @Output() public quantityPerOuterChanged = new EventEmitter<OuterInfo>();

  public isFinishedChecked = false;
  public currentLabelInfoFocus = false;
  public currentLabelInfoPending = false;
  public currentLabelInfo: string;
  public qaCheckForm: UntypedFormGroup;
  public allOkCheck: UntypedFormControl = new UntypedFormControl();
  public loadingTopic: string;
  public palletButtons: ButtonItem[];
  public containerStatus: StatusParameter;
  public palletQualityStatus: StatusParameter;
  public palletPrintInfo: PalletPrintInfo;
  public isPalletActive = false;
  public isPalletFinished = false;
  public isWasteDone = false;
  public isPrintDone = false;
  public isPrintFailed = false;
  public isPrintPending = false;
  public isCheckDone = false;
  public isShowGrossQuantity = false;
  public showStatus = true;

  public printStatus: ProducedMaterialCheckStatus;
  public checkStatus: ProducedMaterialCheckStatus;
  public wasteStatus: ProducedMaterialCheckStatus;
  public containForwardWaste: ProducedMaterialCheckStatus;

  public forwardedWasteState = ProducedMaterialCheckState.NONE;
  public forwardedWasteResult = ProducedMaterialCheckResult.NONE;
  public forwardWasteButtonYes = null;
  public forwardWasteButtonNo = null;
  public isForwardedWaste = null;
  public currentBobbinCount = 0;
  public totalBobbinCount = 0;
  public containerQuantity = 0;
  public isShowBobbinQuantity = false;
  public printInfo: OuterPrintInfo;
  public isSignatureFeatureEnabled = false;

  private readonly MAX_VISIBLE_QA = 3;
  private readonly WASTE_ADD_LOADING_TOPIC = notificationTopic.wasteAssignmentAddAction;
  private readonly OUTER_PRINT_LOADING_TOPIC = notificationTopic.outerPrintLabelAction;
  private readonly OUTER_PREPRINT_LOADING_TOPIC = notificationTopic.outerPreprintLabelAction;

  private allPalletsFinished = false;
  private isWasteAssigned = false;
  private subscriptions = new Subscription();
  private qaText = 'NotApplicable';

  constructor(
    private outputContainerService: OutputContainerService,
    private outputPalletQuery: OutputPalletsQuery,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private qaCheckService: QaCheckService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private uiStateService: UiStateService,
    private uiStateQuery: UiStateQuery,
    private appSettingsQuery: AppSettingsQuery,
    private producedMaterialService: ProducedMaterialDsService,
    private documentService: DocumentsService,
    private signatureHandlerService: SignatureHandlerService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.outputPalletQuery.allPalletsFinished$.subscribe((finishedPallets) => {
        this.allPalletsFinished = finishedPallets;
        this.palletButtons = this.buildPalletButtons();
      })
    );
    this.qaCheckForm = this.qaCheckService.qaChecksFormControlsInit(this.outputPallet.qaChecks);
    this.subscriptions.add(
      this.allOkCheck.valueChanges.subscribe((isOk) => {
        this.onAllOkChange(isOk);
      })
    );
    this.isExpanded = !this.uiStateQuery.getRunPhaseProducedMaterialExpanded(this.outputPallet.containerId);

    this.subscriptions.add(
      this.appSettingsQuery.canShowGrossQuantity$.subscribe((flag) => {
        this.isShowGrossQuantity = flag;
      })
    );

    this.setcontainsForwardedWasteStatus(this.outputPallet.containsForwardedWaste);
    this.isShowBobbinQuantity = this.slitCount > 1;
    this.printInfo = this.outputPallet.printInfo;
    this.isSignatureFeatureEnabled = this.signatureHandlerService.isQualityCheckSignatureEnabled();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.outputPallet) {
      this.loadingTopic = notificationTopic.outputContainerAction + this.outputPallet.sequence;
      this.isPalletActive = this.outputPallet.status === ProducedMaterialStatus.ACTIVE;
      this.isPalletFinished = this.outputPallet.status === ProducedMaterialStatus.FINISHED;
      this.isWasteDone = this.outputPallet.wasteAssignmentsResult === ProducedMaterialCheckResult.DONE;
      this.isPrintDone = this.outputPallet.labelPrintResult === LabelPrintResult.DONE;
      this.isPrintFailed = this.outputPallet.labelPrintResult === LabelPrintResult.FAILED;
      this.isPrintPending = this.outputPallet.labelPrintResult === LabelPrintResult.OPEN;
      this.isCheckDone = this.outputPallet.qaChecksResult === ProducedMaterialCheckResult.DONE;
      this.forwardedWasteState = this.outputPallet.forwardedWasteState;
      this.forwardedWasteResult = this.outputPallet.forwardedWasteResult;
      this.isForwardedWaste = this.outputPallet.containsForwardedWaste;
      this.isFinishedChecked = this.isPalletFinished;
      this.printStatus = this.getPrintStatus();
      this.checkStatus = this.getCheckStatus();
      this.wasteStatus = this.getWasteStatus();
      this.containForwardWaste = this.getContainForwardWaste();
      this.containerStatus = this.getStatusFlag();
      this.palletButtons = this.buildPalletButtons();
      this.palletQualityStatus = this.getPalletQualityStatus();
      this.updateCurrentLabelInfo(this.outputPallet.labelInfo);
      this.containerQuantity = this.outputPallet.containerQuantity.value;
      this.currentBobbinCount = this.slitCount > 1 ? this.getCurrentBobbinCount() : 0;
      this.totalBobbinCount = this.slitCount > 1 ? this.getTotalBobbinCount() : 0;
    }
  }

  public corePrintBobbinLabel(printLabel: BobbinPrintLabel) {
    const loadingTopic = printLabel.entry.reprint ? this.OUTER_PRINT_LOADING_TOPIC : this.OUTER_PREPRINT_LOADING_TOPIC;
    LoadingNotificationService.publish(loadingTopic, true);
    this.documentService
      .printBobbinCoreLabel(printLabel)
      .pipe(
        tap(() => LogService.success('SUCCESS_MESSAGE.PRINTED_LABELS')),
        finalize(() => LoadingNotificationService.publish(loadingTopic, false))
      )
      .subscribe();
  }

  public quantityPerOuterAndCopiesChange(info: OuterInfo): void {
    this.outputContainerService
      .changeProducedMaterialPrintInfo(this.outputPallet.producedMaterialId, OuterLabelType.CORE, info.quantityPerOuter, info.numberCopies)
      .subscribe();
  }

  private updateCurrentLabelInfo(value: string): void {
    if (!this.currentLabelInfoFocus && !this.currentLabelInfoPending) {
      this.currentLabelInfo = value;
    }
  }

  public onRowAdded(producedMaterialId: number, wasteAssignment: WasteAssignmentFormEvent): void {
    LoadingNotificationService.publish(this.WASTE_ADD_LOADING_TOPIC + this.outputPallet.sequence, true);
    this.outputContainerService
      .addProducedMaterialWasteAssignment(producedMaterialId, wasteAssignment)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.WASTE_ADD_LOADING_TOPIC + this.outputPallet.sequence, false);
        })
      )
      .subscribe();
  }

  public onRowRemoved(producedMaterialId: number, wasteAssignmentId: number): void {
    this.outputContainerService.removePalletWasteAssignment(producedMaterialId, wasteAssignmentId).subscribe();
  }

  public onWasteAssign(isAssigned: boolean): void {
    this.isWasteAssigned = isAssigned;
  }

  public onChangePalletBobbinQuantityClicked(): void {
    this.changePalletBobbinQuantityClicked.emit({
      productionOrderId: this.outputPallet.productionOrderId,
      producedMaterialId: this.outputPallet.producedMaterialId,
      isCalledFromPallet: true,
      containerBobbinQuantity: this.outputPallet.containerBobbinQuantity,
      containerQuantity: this.outputPallet.containerQuantity
    });
  }

  public printLabel(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.outputContainerService
      .printLabel(this.outputPallet.containerId, this.isPrintDone)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe();
  }

  public onFinishedCheckChange(): void {
    this.finishedCheck.emit({
      producedMaterialId: this.outputPallet.producedMaterialId,
      producedMaterialStatus: this.outputPallet.status,
      isFinished: this.isFinishedChecked,
      loadingTopic: this.loadingTopic
    });
  }

  public onFinishClick(): void {
    if (this.grossQuantity.value > 0) {
      this.dialogService
        .open(ModalConfirmComponent, {
          header: this.translateService.instant('PALLET_CARD.FINISH_ORDER_GROSS_QUANTITY_WARNING_HEADER'),
          data: {
            question: this.translateService.instant('PALLET_CARD.FINISH_ORDER_GROSS_QUANTITY_WARNING', {
              amount: this.grossQuantity.value
            }),
            acceptable: true,
            swapButtons: true
          }
        })
        .onClose.pipe(filter((accepted) => !!accepted))
        .subscribe(() => {
          this.emitFinishEvent(OutputFinishState.Finish);
        });
    } else {
      this.emitFinishEvent(OutputFinishState.Finish);
    }
  }

  public onSwitchClick(): void {
    this.emitFinishEvent(OutputFinishState.Switch);
  }

  public onInterruptClick(): void {
    this.dialogService
      .open(ModalConfirmComponent, {
        header: this.translateService.instant('PALLET_CARD.INTERRUPT_ORDER'),
        data: {
          question: this.translateService.instant('PALLET_CARD.INTERRUPT_QUESTION'),
          acceptable: false
        }
      })
      .onClose.pipe(filter((accepted) => !!accepted))
      .subscribe(() => {
        this.emitFinishEvent(OutputFinishState.Interrupt);
      });
  }

  private emitFinishEvent(state: OutputFinishState): void {
    this.finishClicked.emit({
      finishState: state,
      producedMaterialId: this.outputPallet.producedMaterialId,
      loadingTopic: this.loadingTopic
    });
  }

  public onNotLastClick(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.outputContainerService
      .markPalletAsNotLast(this.outputPallet.producedMaterialId, this.activeWorkCenterService.getWorkCenterId())
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe();
  }

  public onLastClick(): void {
    this.lastClicked.emit({
      producedMaterialId: this.outputPallet.producedMaterialId,
      loadingTopic: this.loadingTopic
    });
  }

  public toggleContainer(): void {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.uiStateService.setRunPhaseProducedMaterialExpand(this.outputPallet.containerId);
    } else {
      this.uiStateService.setRunPhaseProducedMaterialCollapse(this.outputPallet.containerId);
    }
  }

  public onContainerQuantityChanged(): void {
    this.changeContainerQuantityClicked.emit({
      productionOrderId: this.outputPallet.productionOrderId,
      producedMaterialId: this.outputPallet.producedMaterialId,
      quantity: this.outputPallet.containerQuantity,
      ssccCode: this.outputPallet.ssccCode,
      sequenceNumber: this.outputPallet.sequence,
      classification: this.outputPallet.article.classification,
      isLastContainer: this.outputPallet.isLastContainer
    });
  }

  private isContainerQuantityChangingVisible(): boolean {
    const status = this.outputPallet.status;
    return status === ProducedMaterialStatus.ACTIVE || status === ProducedMaterialStatus.OPEN || this.outputPallet.isLastContainer;
  }

  public onPrintLabelInfoFocus(): void {
    this.currentLabelInfoFocus = true;
  }

  public onPrintLabelInfoBlur(): void {
    this.currentLabelInfoFocus = false;
    this.currentLabelInfoPending = true;
    this.outputContainerService
      .setPrintLabelInfo(this.outputPallet.containerId, this.currentLabelInfo)
      .pipe(finalize(() => (this.currentLabelInfoPending = false)))
      .subscribe();
  }

  public openQaChecksModal(): void {
    this.dialogService
      .open(QaChecksModalComponent, {
        header: this.translateService.instant('PALLET_CARD.QA_CHECKS'),
        data: {
          pallet: this.outputPallet
        }
      })
      .onClose.subscribe(() => {
        this.allOkCheck.setValue(this.qaCheckService.isAllChecksOk(this.qaCheckForm), { emitEvent: false });
      });
  }

  public isQaCheckListReduced(): boolean {
    if(!this.isSignatureFeatureEnabled) {
      return this.isQaCheckListAvailable() && this.outputPallet.qaChecks.length <= this.MAX_VISIBLE_QA;
    }
  }

  public isQaCheckListAvailable(): boolean {
    return this.outputPallet.qaChecks.length > 0;
  }

  public onAllOkChange(allOk: boolean): void {
    if (this.qaCheckForm) {
      this.qaCheckService.setFormFieldsForProducedMaterialToOk(this.outputPallet.producedMaterialId, allOk, this.qaCheckForm);
    }
  }

  public isContainerDone(): boolean {
    return this.isSignatureFeatureEnabled
        ? this.outputPallet.canFinish && this.isCheckDone
        : this.outputPallet.canFinish;
  }

  public isContainerFieldDone(producedMaterialCheckStatus: ProducedMaterialCheckStatus[]): boolean {
    return producedMaterialCheckStatus
      .filter((producedMaterial) => producedMaterial.state === ProducedMaterialCheckState.MANDATORY)
      .every((currentResult: ProducedMaterialCheckStatus) => currentResult.result === ProducedMaterialCheckResult.DONE);
  }

  public getProducedMaterialItemStatus(result: LabelPrintResult, state: ProducedMaterialCheckState): ProducedMaterialCheckStatus {
    return { result, state } as ProducedMaterialCheckStatus;
  }

  public isPalletQaCheckDone(): boolean {
    return this.isContainerFieldDone([this.checkStatus]);
  }

  public isFinishedRequired(): boolean {
    return this.isContainerDone() && !this.isFinishedChecked;
  }

  public containsForwardedWaste(containForwardWaste: boolean): void {
    if (this.outputPallet.status !== ProducedMaterialStatus.FINISHED) {
      this.outputPallet.containsForwardedWaste = containForwardWaste;
    }

    this.producedMaterialService
      .setProducedForwardedWaste(this.outputPallet)
      .pipe(finalize(() => this.setcontainsForwardedWasteStatus(this.outputPallet.containsForwardedWaste)))
      .subscribe();
  }

  private setcontainsForwardedWasteStatus(containForwardWaste?: boolean): void {
    if (containForwardWaste != null) {
      if (containForwardWaste) {
        this.forwardWasteButtonYes = 'result-select-button__item--ok-active';
        this.forwardWasteButtonNo = null;
      } else {
        this.forwardWasteButtonYes = null;
        this.forwardWasteButtonNo = 'result-select-button__item--nok-active';
      }

      this.isForwardedWaste = true;
    }
  }

  private getPrintStatus(): ProducedMaterialCheckStatus {
    return this.getProducedMaterialItemStatus(this.outputPallet.labelPrintResult, this.outputPallet.labelPrintState);
  }

  private getWasteStatus(): ProducedMaterialCheckStatus {
    return this.getProducedMaterialItemStatus(this.outputPallet.wasteAssignmentsResult, this.outputPallet.wasteAssignmentsState);
  }

  private getCheckStatus(): ProducedMaterialCheckStatus {
    return this.getProducedMaterialItemStatus(this.outputPallet.qaChecksResult, this.outputPallet.qaChecksState);
  }

  private getContainForwardWaste(): ProducedMaterialCheckStatus {
    return this.getProducedMaterialItemStatus(this.outputPallet.forwardedWasteResult, this.outputPallet.forwardedWasteState);
  }

  private getCurrentBobbinCount(): number {
    return Math.ceil(this.outputPallet.goodQuantity.value / this.outputPallet.containerBobbinQuantity.value) * this.slitCount;
  }
  private getTotalBobbinCount(): number {
    return Math.ceil(this.outputPallet.containerQuantity.value / this.outputPallet.containerBobbinQuantity.value) * this.slitCount;
  }

  private getStatusFlag(): StatusParameter {
    if (this.outputPallet.isLastContainer) {
      return producedMaterialStatusFlags[LAST_FLAG_STATUS];
    } else {
      return producedMaterialStatusFlags[this.outputPallet.status];
    }
  }

  private getPalletQualityStatus(): StatusParameter {
    if (this.outputPallet.containerCheckResult) {
      if (this.outputPallet.containerCheckResult === this.qaText) this.showStatus = false;

      return palletQualtityStatusFlags[this.outputPallet.containerCheckResult];
    }
  }

  private buildPalletButtons(): ButtonItem[] {
    return this.outputPallet.isLastContainer ? this.buildLastPalletButtons() : this.buildNotLastPalletButtons();
  }

  private buildLastPalletButtons(): ButtonItem[] {
    return [
      {
        command: () => this.onFinishClick(),
        label: 'PALLET_CARD.FINISH',
        primary: this.allPalletsFinished,
        visible: this.allPalletsFinished
      },
      {
        command: () => this.onInterruptClick(),
        label: 'PALLET_CARD.INTERRUPT',
        visible: this.allPalletsFinished
      },
      {
        command: () => this.onSwitchClick(),
        label: 'PALLET_CARD.SWITCH_ORDER',
        visible: this.manualMode && !this.allPalletsFinished
      },
      {
        command: () => this.onNotLastClick(),
        label: 'PALLET_CARD.NOT_LAST',
        primary: !this.allPalletsFinished
      },
      {
        command: () => this.printLabel(),
        label: this.getPrintLabelTranslation(),
        visible: this.isPalletFinished && !this.isPrintPending && !this.isPrintFailed
      },
      {
        command: () => this.onContainerQuantityChanged(),
        label: 'PALLET_CARD.CHANGE_QTY_PER_PALLET',
        visible: this.isContainerQuantityChangingVisible()
      },
      {
        command: () => this.onChangePalletBobbinQuantityClicked(),
        label: 'RUN_PHASE.CHANGE_BOBBIN_QTY',
        disabled: false,
        visible: this.slitCount > 1
      }
    ];
  }

  private buildNotLastPalletButtons(): ButtonItem[] {
    return [
      {
        command: () => this.printLabel(),
        label: this.getPrintLabelTranslation(),
        primary: this.isPalletFinished,
        visible: this.isPalletFinished && !this.isPrintPending && !this.isPrintFailed
      },
      {
        command: () => this.onLastClick(),
        label: 'PALLET_CARD.LAST'
      },
      {
        command: () => this.onSwitchClick(),
        label: 'PALLET_CARD.SWITCH_ORDER',
        visible: this.manualMode
      },
      {
        command: () => this.onContainerQuantityChanged(),
        label: 'PALLET_CARD.CHANGE_QTY_PER_PALLET',
        visible: this.isContainerQuantityChangingVisible()
      },
      {
        command: () => this.onChangePalletBobbinQuantityClicked(),
        label: 'RUN_PHASE.CHANGE_BOBBIN_QTY',
        disabled: false,
        visible: this.slitCount > 1
      }
    ];
  }

  private getPrintLabelTranslation(): string {
    return this.isPrintDone ? 'PALLET_CARD.RE_PRINT' : 'PALLET_CARD.PRINT_LABEL';
  }
}
