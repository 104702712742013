<div *ngIf="isReadOnly" class="readonly-message">
 {{ 'SIGNATURE.READONLY_MESSAGE' | translate }}
</div>

<div class="p-grid" [ngClass]="{'readonly-mode': isReadOnly}">
  <div class="p-col quality-assurance" *ngIf="fields$ | async as fields">
    <app-qa-check-list-in-setup 
      [fields]="fields" 
      (formValid)="onFormValidityChange($event)"
      [signatureConfig]="signatureConfig"
       ></app-qa-check-list-in-setup>

       <div class="separator" *ngIf="enableQualityCheckSignature"></div>

       <app-signature *ngIf="enableQualityCheckSignature" [ngClass]="{'readonly-mode': isReadOnly}"
        [productionQaCheck] = "fields"
      ></app-signature>
  </div>
</div>


