<div class="disconnected-mode" pTooltip="{{ tooltipText }}">
  <div
    class="label-icon"
    [ngClass.xs]="{ 'label-icon': false, 'label-icon-mobile': true }"
    [ngClass.sm]="{ 'label-icon': false, 'label-icon-mobile': true }"
  >
    <i class="icon-plug-disconnected" aria-hidden="true"></i>
  </div>
  <div class="labelText">
    {{ 'SPEED_MONITOR.DISCONNECTED' | translate }}
  </div>
</div>
