import { Injectable } from '@angular/core';
import { MountingCardViewOptions } from '@app/modules/mounting/models';
import { arrayAdd, arrayRemove } from '@datorama/akita';
import { UiStateStore } from './ui-state.store';
import { ApprovalReportSelectionState } from './ui-state.model';

@Injectable({ providedIn: 'root' })
export class UiStateService {
  constructor(private uiStore: UiStateStore) {}

  public setShiftReportStateSelectedShift(shiftId: number): void {
    this.uiStore.update({ shiftReportSelectedShiftId: shiftId });
  }

  public setApprovalReportStateSelectionState(state: ApprovalReportSelectionState): void {
    this.uiStore.update({ approvalReportSelectionState: state });
  }

  public setMountingStateSelectedRoute(mountingSelectedRoute: string): void {
    this.uiStore.update({ mountingSelectedRoute });
  }

  public setEmployeeRegistrationStateSelectedRoute(employeeSelectedRoute: string): void {
    this.uiStore.update({ employeeSelectedRoute });
  }

  public setMountingStateSelectedOrderId(mountingSelectedOrderId: number): void {
    this.uiStore.update({ mountingSelectedOrderId });
  }

  public setMountingPrimaryListview(mountingPrimaryListview: MountingCardViewOptions): void {
    this.uiStore.update({ mountingPrimaryListview });
  }

  public setDocumentStateSelectedRoute(documentSelectedRoute: string): void {
    this.uiStore.update({ documentSelectedRoute });
  }

  public setDocumentStateSelectedOrderId(documentSelectedOrderId: number): void {
    this.uiStore.update({ documentSelectedOrderId });
  }

  public setMountingTraceArticleVisible(visibleId: number): void {
    this.uiStore.update((state) => ({
      mountingTraceExpandedArticles: arrayAdd(state.mountingTraceExpandedArticles, visibleId)
    }));
  }

  public setMountingTraceArticleHide(hideId: number): void {
    this.uiStore.update((state) => ({
      mountingTraceExpandedArticles: arrayRemove(state.mountingTraceExpandedArticles, hideId)
    }));
  }

  public setMountingSecondaryExpandedArticleVisible(visibleId: number): void {
    this.uiStore.update((state) => ({
      mountingSecondaryExpandedArticles: arrayAdd(state.mountingSecondaryExpandedArticles, visibleId)
    }));
  }

  public setMountingSecondaryExpandedArticleHide(hideId: number): void {
    this.uiStore.update((state) => ({
      mountingSecondaryExpandedArticles: arrayRemove(state.mountingSecondaryExpandedArticles, hideId)
    }));
  }

  public setMountingSecondaryMountedExpandedArticleVisible(visibleId: number): void {
    this.uiStore.update((state) => ({
      mountingSecondaryMountedExpandedArticles: arrayAdd(state.mountingSecondaryMountedExpandedArticles, visibleId)
    }));
  }

  public setMountingSecondaryMountedExpandedArticleHide(hideId: number): void {
    this.uiStore.update((state) => ({
      mountingSecondaryMountedExpandedArticles: arrayRemove(state.mountingSecondaryMountedExpandedArticles, hideId)
    }));
  }

  public orderListExpandedOrder(productionOrderId: number): void {
    this.uiStore.update((state) => ({
      orderListExpandedOrders: arrayAdd(state.orderListExpandedOrders, productionOrderId)
    }));
  }

  public orderListCollapseOrder(productionOrderId: number): void {
    this.uiStore.update((state) => ({
      orderListExpandedOrders: arrayRemove(state.orderListExpandedOrders, productionOrderId)
    }));
  }

  public setRunPhaseProducedMaterialExpand(visbleId: number): void {
    this.uiStore.update((state) => ({
      runPhaseExpandedProducedMaterials: arrayRemove(state.runPhaseExpandedProducedMaterials, visbleId)
    }));
  }

  public setRunPhaseProducedMaterialCollapse(hideId: number): void {
    this.uiStore.update((state) => ({
      runPhaseExpandedProducedMaterials: arrayAdd(state.runPhaseExpandedProducedMaterials, hideId)
    }));
  }
}
