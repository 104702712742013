<section class="trace-table">
  <p-table [value]="tracePatternData">
    <ng-template pTemplate="header">
      <tr>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.KNIFE' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.POSITION' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.TRACK' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.ORDER' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.DIMENSION' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="trace-table__text-cell">{{ rowData.knife }}</td>
        <td class="trace-table__text-cell">{{ rowData.position.value }} {{ rowData.position.unitId }}</td>
        <td class="trace-table__text-cell">{{ rowData.trackNumber }}</td>
        <td class="trace-table__text-cell">{{ rowData.externalProductionOrderId }}</td>
        <td class="trace-table__text-cell">
          <span *ngIf="rowData.sheetLength; else noData">{{ rowData.sheetLength?.value }} x {{ rowData.sheetWidth?.value }}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">{{ 'APP.NO_DATA_FOUND' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template #noData>
    <span></span>
  </ng-template>
</section>
