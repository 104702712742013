import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QaCheckService } from '@app/core/services';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { QaCheckValueChange } from '@app/shared/models';
import { ProductionQaCheck } from 'chronos-core-client';
import { sort } from 'ramda';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-line-clearance-list',
  templateUrl: './line-clearance-list.component.html',
  styleUrls: ['./line-clearance-list.component.scss']
})
export class LineClearanceListComponent implements OnInit, OnDestroy {
  @Input() public fields: ProductionQaCheck[];
  @Input() public form: UntypedFormGroup;
  @Input() public scrollHeight = '400px';
  public allOk: boolean;
  private previousForm: QaCheckValueChange[];
  private subscriptions = new Subscription();
  private isSignatureEnabled = false;
  constructor(public qaCheckService: QaCheckService,
    private signatureHandlerService: SignatureHandlerService
  ) {}

  private static sortCheckResult(checkResult: ProductionQaCheck[]): ProductionQaCheck[] {
    const diff = (a: ProductionQaCheck, b: ProductionQaCheck) => a.checkSequenceNumber - b.checkSequenceNumber;
    return sort(diff, checkResult);
  }

  public ngOnInit(): void {
    this.updateAllOkCheck();
    this.subscriptions.add(
      this.qaCheckService.controlsEmittersInit(this.form).subscribe((qaCheckResult) => {
        this.setCheckResult(qaCheckResult);
      })
    );
    this.fields = LineClearanceListComponent.sortCheckResult(this.fields);
    this.isSignatureEnabled = this.signatureHandlerService.isQualityCheckSignatureEnabled();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setCheckResult(qaCheckResult: QaCheckValueChange) {
    const qaCheckControl = this.qaCheckService.getCheckControl(qaCheckResult.id, this.form, this.fields);
    qaCheckControl.disable({ emitEvent: false });

    this.qaCheckService
      .setCheckResult(qaCheckResult.id, qaCheckResult.check, qaCheckResult.comment, qaCheckResult.measurementResult)
      .pipe(
        finalize(() => {
          qaCheckControl.enable({ emitEvent: false });
        })
      )
      .subscribe(
        () => {
          this.updateAllOkCheck();

          // Check if signature is enabled
          if(this.isSignatureEnabled) {
            this.handleResetSignature(qaCheckResult);
          }
        },
        () => {
          this.resetFormToPreviousState();
        }
      );
  }

  private resetFormToPreviousState(): void {
    this.form.reset(this.previousForm, { onlySelf: false, emitEvent: false });
    this.form.updateValueAndValidity();
  }

  private updateAllOkCheck(): void {
    this.allOk = this.qaCheckService.isAllChecksOk(this.form);
    this.previousForm = this.form.value;
  }

  /**
   * Handle reset signature
   * @param qaCheckResult
   * @private
   * @memberof LineClearanceListComponent
   */
  private handleResetSignature(qaCheckResult: QaCheckValueChange): void {
    const isSignaturePresent = this.signatureHandlerService.isSignaturePresent();
    if(isSignaturePresent && this.fields?.length){
      const check = this.findQaCheckById(qaCheckResult.id);
      if(check) {
        this.updateQaCheckResult(check);
      }
      this.signatureHandlerService.resetSignature();
    }
  }

  /**
   * Find the QA check by id
   * @param id 
   * @returns 
   */
  private findQaCheckById(id: number): ProductionQaCheck | undefined {
    const qaCheck = this.fields?.find((check) => check.id === id);
    if (!qaCheck) {
      console.error(`QA Check with id ${id} not found`);
      return undefined;
    }

    const qaCheckId = qaCheck.qaCheckId;
    return this.fields?.find((check) => check.qaCheckId === qaCheckId);
  }

  /**
   * Update QA check result
   */
  public updateQaCheckResult(qaCheck: ProductionQaCheck): void {
    const { id, checkResult, comment, measurementResult } = qaCheck;

    this.qaCheckService
      .setCheckResult(id, checkResult, comment, measurementResult)
      .pipe(finalize(() => {}))
      .subscribe();
  }
}
