<section class="end page-padding">
  <header>
    <h1 class="page-header">
      {{ 'FINISH_PHASE.END_HEADER' | translate }}
    </h1>
  </header>

  <div class="end-block" [formGroup]="form">
    <div class="end-block__header">
      {{ 'FINISH_PHASE.INFO_TO_KPI' | translate }}
    </div>
    <div class="end-block__info" [ngClass]="{ 'end-block__info--required': commentValidation }">
      <textarea formControlName="kpiInfo" rows="5" cols="120" pInputTextarea autoResize (blur)="saveKpiInfo()"></textarea>
    </div>
    <div class="end-block__info">
      <div class="kpi-grid p-grid">
        <div class="column-width" *ngFor="let kpi of kpiQuery.warningKpis$ | async">
          <app-kpi-tile [kpi]="kpi"></app-kpi-tile>
        </div>
      </div>
    </div>
  </div>
  <div class="end-block">
    <div class="end-block__header">
      {{ 'FINISH_PHASE.INFO_TO_PRODUCTION_ORDER' | translate }}
    </div>
    <div class="end-block__info">
      <textarea
        rows="5"
        cols="120"
        pInputTextarea
        autoResize
        class="info-to-production-order__item"
        [(ngModel)]="infoToProductionOrder"
        (blur)="saveKpiInfo()"
      ></textarea>
    </div>
  </div>
</section>
