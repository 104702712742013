<ng-container *ngIf="informationData$ | async as documentInfo">
  <section class="information-section">
    <app-remark-container [sectionName]="'DOCUMENTS.PRODUCTION_ORDER'">
      <app-remark-list [productionOrder]="documentInfo.productionOrder" [showHeader]="true">
        <ng-container
          *appRemarkAction="let remark"
          [ngTemplateOutlet]="remarkAction"
          [ngTemplateOutletContext]="{ $implicit: remark, model: documentInfo.productionOrder }"
        ></ng-container>
      </app-remark-list>
    </app-remark-container>

    <app-remark-container [sectionName]="'DOCUMENTS.PRODUCTION_STEPS'">
      <div *ngIf="documentInfo.previousProductionSteps.length > 0; else emptyList">
        <app-remark-list
          *ngFor="let step of documentInfo.previousProductionSteps"
          [productionOrder]="step"
          [showHeader]="true"
        ></app-remark-list>
      </div>
    </app-remark-container>

    <app-remark-container [sectionName]="'DOCUMENTS.FOLLOWING_STEPS'">
      <div *ngIf="documentInfo.nextProductionSteps.length > 0; else emptyList">
        <app-remark-list
          *ngFor="let step of documentInfo.nextProductionSteps"
          [productionOrder]="step"
          [showHeader]="true"
          [showRemarks]="false"
        ></app-remark-list>
      </div>
    </app-remark-container>
  </section>
</ng-container>

<ng-template #remarkAction let-remark let-step="model">
  <ng-container *ngIf="isRemarkEditable(remark, step)">
    <lib-button (clicked)="editRemarkInfo(remark, step)" [label]="'DOCUMENTS.EDIT'" class="edit-button"></lib-button>
  </ng-container>
</ng-template>

<ng-template #emptyList>
  <div class="remark">
    <div class="remark__field">
      {{ 'DOCUMENTS.NO_INFORMATION' | translate }}
    </div>
  </div>
</ng-template>
