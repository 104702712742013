import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OutputContainerService } from '@app/modules/run-phase/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { elastic } from '@app/shared/utils/elastic';

@Component({
  selector: 'app-change-bobbin-quantity',
  templateUrl: './change-bobbin-quantity.component.html',
  styleUrls: ['./change-bobbin-quantity.component.scss']
})
export class ChangeBobbinQuantityComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalChangeOutputQuantity;

  public modalForm: UntypedFormGroup;
  public changeFollowingPallets = false;
  public isChangeFollowingBobbinsEnabled = false;
  public unit: string;
  public quantity: number;

  private readonly INPUT_MAX_QUANTITY = 9999999;
  private readonly INPUT_MIN_QUANTITY = 1;

  private productionOrderId: number;
  private producedMaterialId: number;
  private containerQuantity: number;

  constructor(private outputContainerService: OutputContainerService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.initModalForm();
    this.initModalData();
    this.changeMaxQuantityValidator(this.containerQuantity);
  }

  private initModalForm(): void {
    this.modalForm = this.modalForm = new UntypedFormGroup({
      quantity: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.INPUT_MIN_QUANTITY),
        Validators.max(this.INPUT_MAX_QUANTITY)
      ])
    });
  }

  private changeMaxQuantityValidator(newMaxQuantity: number) {
    const quantityControl = this.modalForm.get('quantity');
    quantityControl?.setValidators([Validators.required, Validators.min(this.INPUT_MIN_QUANTITY), Validators.max(newMaxQuantity)]);

    quantityControl?.updateValueAndValidity();
  }

  private initModalData(): void {
    this.productionOrderId = this.config.data?.productionOrderId;
    const isCalledFromPallet = this.config.data?.bobbinDetails?.isCalledFromPallet ?? false;

    if (isCalledFromPallet) {
      this.isChangeFollowingBobbinsEnabled = true;
      this.unit = this.config.data?.bobbinDetails?.containerBobbinQuantity?.unitId;
      this.quantity = this.config.data?.bobbinDetails?.containerBobbinQuantity?.value;
      this.producedMaterialId = this.config.data?.bobbinDetails?.producedMaterialId;
      this.containerQuantity = this.config.data?.bobbinDetails?.containerQuantity.value;
    } else {
      this.unit = this.config.data?.bobbinQuantity?.unitId;
      this.quantity = this.config.data?.bobbinQuantity?.value;
      this.containerQuantity = this.config.data?.containerQuantity.value;
    }

    if (this.quantity) {
      this.modalForm.controls.quantity.setValue(this.quantity);
    }
  }

  private setOutputPoolBobbinQuantity(): void {
    const trace = elastic.traceUiActivity('ChangeBobbinQuantityComponent.setOutputPoolBobbinQuantity');
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const quantityValue = this.modalForm.controls.quantity.value;
    this.outputContainerService
      .setOutputPoolBobbinQuantity(this.productionOrderId, quantityValue)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe(() => {
        this.closeModal();
      });
  }

  private setParticularContainerBobbinQuantity(): void {
    const trace = elastic.traceUiActivity('ChangeBobbinQuantityComponent.setParticularContainerBobbinQuantity');
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    const quantityValue = this.modalForm.controls.quantity.value;

    this.outputContainerService
      .changeContainerBobbinQuantity(this.producedMaterialId, quantityValue, this.unit, this.changeFollowingPallets)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe(() => {
        this.closeModal();
      });
  }

  public closeModal(): void {
    this.ref.close();
  }

  public submitModal(): void {
    if (this.modalForm.valid) {
      if (this.producedMaterialId) {
        this.setParticularContainerBobbinQuantity();
      } else {
        this.setOutputPoolBobbinQuantity();
      }
    } else {
      this.modalForm.markAllAsTouched();
    }
  }
}
