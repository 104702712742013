import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QaCheckService } from '@app/core/services';
import { SignatureConfig } from '@app/shared/models/signature';
import { ProductionQaCheck } from 'chronos-core-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qa-check-list-in-setup',
  templateUrl: './qa-check-list-in-setup.component.html',
  styleUrls: ['./qa-check-list-in-setup.component.scss']
})
export class QaCheckListInSetupComponent implements OnInit, OnDestroy {

  @Input()
  public fields: ProductionQaCheck[];
  @Input() signatureConfig = {} as SignatureConfig;
  @Output() public formValid: EventEmitter<boolean> = new EventEmitter();
  public qualityAssuranceForm: UntypedFormGroup;

  private subscriptions = new Subscription();

  constructor(public qaCheckService: QaCheckService) {}

  public ngOnInit(): void {
    this.qualityAssuranceForm = this.qaCheckService.qaChecksFormControlsInit(this.fields);
    this.subscriptions.add(
      this.qualityAssuranceForm.valueChanges.subscribe(() => {
        this.checkFormValidity();
      })
    );
    this.checkFormValidity();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkFormValidity(): void {
    this.formValid.emit(this.qualityAssuranceForm.valid);
  }
}
