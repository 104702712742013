import { Component, OnInit } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services';
import { NextSetupSubphaseCommandService, PreviousSetupSubphaseCommandService } from '@app/modules/setup-phase/commands';
import { SetupFooterService } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { Observable } from 'rxjs';
import { SetupPhaseService } from '../../services/setup-phase/setup-phase.service';
import { QualityAssuranceService } from './quality-assurance.service';
import { ProductionQaCheck, RunSubPhaseType } from 'chronos-core-client';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { QaCheckType, SignatureConfig } from '@app/shared/models/signature';

@Component({
  selector: 'app-quality-assurance',
  templateUrl: './quality-assurance.component.html',
  styleUrls: ['./quality-assurance.component.scss']
})
export class QualityAssuranceComponent implements OnInit {
  public fields$: Observable<ProductionQaCheck[]>;
  public enableQualityCheckSignature = false;

  public backCommandService: PreviousSetupSubphaseCommandService;
  public nextCommandService: NextSetupSubphaseCommandService;
  public isReadOnly = false;
  public signatureConfig = {} as SignatureConfig;

  constructor(
    private qualityAssuranceService: QualityAssuranceService,
    private activeOrderDsService: ActiveOrderDsService,
    private setupPhaseService: SetupPhaseService,
    private setupFooterService: SetupFooterService,
    private signatureHandlerService: SignatureHandlerService
  ) {}

  public ngOnInit(): void {
    const productionOrderId = this.activeOrderDsService.getActiveOrderId();

    this.fields$ = this.qualityAssuranceService.getQACheckFields(productionOrderId);

    this.setCommands();

    this.enableQualityCheckSignature = this.signatureHandlerService.isQualityCheckSignatureEnabled();
    if(this.enableQualityCheckSignature) {
      
      this.signatureConfig = {
        isSignatureFeatureEnabled: this.enableQualityCheckSignature,
        qaCheckType: QaCheckType.Setup
      };
    }

    this.isReadOnly = this.signatureHandlerService.setReadOnlyState();
  }

  public onFormValidityChange(isQaCheckValid: boolean): void {
    this.nextCommandService.enabled = isQaCheckValid;
  }

  private setCommands(): void {
    this.backCommandService = new PreviousSetupSubphaseCommandService(this.setupFooterService);
    this.backCommandService.setNavigationParams(nav.routes.setupParameters);

    this.nextCommandService = new NextSetupSubphaseCommandService(this.setupFooterService);
    this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_MATERIAL_WASTE, nav.routes.wasteAssignment);

    this.setupPhaseService.setSetupCommands(this.backCommandService, this.nextCommandService);
  }
}
