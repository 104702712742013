import { Component, OnDestroy, OnInit } from '@angular/core';
import { SetupHeaderService } from '@app/core/services';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { HeaderKpiViewModel } from 'chronos-core-client';
import { Observable, Subscription } from 'rxjs';
import { AppSettingsQuery, SpeedMonitorViewMode } from 'chronos-shared';

@Component({
  selector: 'app-phase-header',
  templateUrl: './setup-header.component.html',
  styleUrls: ['./setup-header.component.scss']
})
export class SetupHeaderComponent implements OnInit, OnDestroy {
  public headerKpis$: Observable<HeaderKpiViewModel>;
  public screenMode: SpeedMonitorViewMode = SpeedMonitorViewMode.Small;
  public workCenterId: number;
  public showInactive: boolean;

  private subscriptions = new Subscription();

  constructor(
    private setupHeaderService: SetupHeaderService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.appSettingsQuery.showInactiveElement();
    this.workCenterId = this.activeWorkCenterService.getWorkCenterId();
    this.headerKpis$ = this.setupHeaderService.getHeaderKpis();
    this.subscriptions.add(this.setupHeaderService.getHeaderKpiNotifications().subscribe());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
