import { Injectable } from '@angular/core';
import { EmployeeSignatureService, EmployeeSignatureViewModel, EmployeeViewModel, ScanSignatureEntry, ScanSignatureResponse, SetSignatureForQaChecksEntry } from 'chronos-core-client';
import { ResetSignatureEntry } from 'projects/chronos-core-client/src/public-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSignatureDsService {

  constructor(private employeeSignatureService: EmployeeSignatureService) { }

  public scanSignature(badgeId: string, requireEmployeeRegistration: boolean,
    workCenterId: number) : 
    Observable<ScanSignatureResponse> {
    const scanSignatureEntry: ScanSignatureEntry = {
      badgeId,
      requireEmployeeRegistration,
      workCenterId
    };

    return this.employeeSignatureService.scanSignature(scanSignatureEntry);
  }

  public setSignatureForQaChecks(comment: string, employeeId: number,
    isManualInput: boolean, 
    productionQaCheckIds: Array<number>, timestamp: string, workCenterId: number) :
    Observable<null> {

    const setSignatureForQaChecksEntry: SetSignatureForQaChecksEntry = {
      comment,
      employeeId,
      isManualInput,
      productionQaCheckIds,
      timestamp,
      workCenterId
    };

    return this.employeeSignatureService.setSignatureForQaChecks(setSignatureForQaChecksEntry);
  }

  public resetSignature(employeeSignatureId: number,
    invalidateSignatureOnly: boolean,
    workCenterId: number) :
    Observable<null> {

    const resetSignatureEntry: ResetSignatureEntry = {
      employeeSignatureId,
      invalidateSignatureOnly,
      workCenterId
    };

    return this.employeeSignatureService.resetSignature(resetSignatureEntry);
  }

  public getEmployeeSignature(employeeSignatureId: number) 
  : Observable<EmployeeSignatureViewModel> {
    return this.employeeSignatureService.getEmployeeSignature(employeeSignatureId);
  }

  public getEmployeesForSignature() : Observable<EmployeeViewModel> {
    return this.employeeSignatureService.getEmployeesForSignature();
  }
  
}
