import { Component, OnDestroy, OnInit } from '@angular/core';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { QaCheckType, SignatureConfig } from '@app/shared/models/signature';
import { ProducedMaterial, ProductionQaCheck, Quantity } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-qa-checks',
  templateUrl: './qa-checks-modal.component.html',
  styleUrls: ['./qa-checks-modal.component.scss']
})
export class QaChecksModalComponent implements OnInit, OnDestroy {
  
  public fields: ProductionQaCheck[];
  public currentPallet: ProducedMaterial;
  public waste: Quantity;
  public isConfirmButtonEnabled = false;
  public isSignatureEnabled = false;
  public signatureConfig = {} as SignatureConfig;
  private subscriptions = new Subscription();
  private confirmButtonStatus = false;

  constructor(private ref: DynamicDialogRef, private conf: DynamicDialogConfig, 
    private signatureHandlerService : SignatureHandlerService) {}

  public ngOnInit(): void {
    this.currentPallet = this.conf.data.pallet;
    this.fields = this.currentPallet.qaChecks;
    this.waste = this.getWasteQuantity(this.currentPallet);

    this.isSignatureEnabled = this.signatureHandlerService.isQualityCheckSignatureEnabled();
    if(this.isSignatureEnabled) {
      this.checkSignatureIsPresent();
      this.signatureConfig = {
        isSignatureFeatureEnabled: this.isSignatureEnabled,
        qaCheckType: QaCheckType.Container
      };
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public submitModal(): void {
    this.ref.close();
  }

  public setConfirmButtonStatus(status: boolean): void {
    this.confirmButtonStatus = status;
    if(this.isSignatureEnabled) {
      this.isConfirmButtonEnabled = this.signatureConfig.isSignaturePresent && status;
    } else {
      this.isConfirmButtonEnabled = status;
    }
  }

  private getWasteQuantity(pallet: ProducedMaterial): Quantity {
    const value = pallet.wasteAssignments.reduce((result, item) => item.waste.value + result, 0);
    const unitId = pallet.containerQuantity.unitId;

    return {
      value,
      unitId
    };
  }

    /**
   * Check if the signature is present
   * @private
   * @returns boolean
   */
    private checkSignatureIsPresent(): void {
      this.subscriptions.add(
        this.signatureHandlerService.signatureIsPresent$.subscribe((signatureIsPresent) => {  
          this.signatureConfig.isSignaturePresent = signatureIsPresent;
          this.updateConfirmButtonStatus();
        })
      );
    }

    private updateConfirmButtonStatus() {
      this.isConfirmButtonEnabled = this.signatureConfig.isSignaturePresent && this.confirmButtonStatus; 
    }
}
