<section class="resource page-padding" [formGroup]="form" [ngClass]="{ 'freeze-element': isUpdating }">
  <div class="row">
    <div class="col-12 resource__setting-header-container">
      <div class="col-6 resource__setting-header">
        <app-resource-header (workCenterChange)="workCenterChange($event)" [isDisabled]="isDisabled"></app-resource-header>
      </div>
      <div class="col-6 resource__setting-header" *ngIf="settingEntityInfo">
        <div class="row">
          <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.TECHNOLOGY' | translate }}</div>
          <div class="col-2 setting_detail_value">{{ settingEntityInfo.operation }}</div>
          <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.NAME' | translate }}</div>
          <div class="col-3 setting_detail_value">{{ settingEntityInfo.name }}</div>
          <div class="col-1 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.ID' | translate }}</div>
          <div class="col-2 setting_detail_value">{{ settingEntityInfo.entityId }}</div>
        </div>
        <div class="row">
          <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.CREATED' | translate }}</div>
          <div class="col-2 setting_detail_value">{{ settingEntityInfo.timeStampCreated | utcToLocal | date : 'dd.MM.yyyy' }}</div>
          <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.UPDATED' | translate }}</div>
          <div class="col-3 setting_detail_value">{{ settingEntityInfo.timeStampModified | utcToLocal | date : 'dd.MM.yyyy' }}</div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="resource__setting-list">
        <div *ngIf="!isError; else noData">
          <ng-container *ngIf="settingsValues$ | async as settings; else loading">
            <ng-container *ngIf="settings.length > 0; else noData" class="row">
              <div *ngFor="let setting of settings">
                <app-setting-category
                  [defaultSettingList]="defaultSettingList"
                  [settingData]="setting"
                  [isAdmin]="isAdmin"
                  [parentForm]="form"
                  [enableEditSetting$]="enableEditSetting$"
                  (viewHistory)="viewHistory($event)"
                  (emitUpdateSetting)="updateSetting($event)"
                  (formValidityChange)="formStatusChange($event)"
                ></app-setting-category>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="enableEditSetting$ | async; else saveSetting" class="resource-config-footer">
  <div class="resource__footer p-grid p-nogutter">
    <div class="resource__footer__leftcorner-wrapper">
      <lib-button
        (clicked)="editSetting()"
        [label]="'RESOURCE_CONFIGURATION.EDITSETTING' | translate"
        [typeClass]="'button--primary'"
        [sizeClass]="'button--small'"
        [disabled]="!isAdmin"
      ></lib-button>
    </div>
    <div *ngIf="isAdmin">
      <lib-button
        (clicked)="importSetting()"
        class="resource__footer--margin-right"
        [label]="'RESOURCE_CONFIGURATION.IMPORT' | translate"
        [typeClass]="'button--primary'"
        [sizeClass]="'button--small'"
      ></lib-button>
      <lib-button
        (clicked)="exportSetting()"
        [label]="'RESOURCE_CONFIGURATION.EXPORT' | translate"
        [typeClass]="'button--primary'"
        [sizeClass]="'button--small'"
      ></lib-button>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="centered-text">{{ 'APP.NO_DATA_FOUND' | translate }}</div>
</ng-template>

<ng-template #loading>
  <lib-loading-indicator></lib-loading-indicator>
</ng-template>

<ng-template #saveSetting>
  <div class="resource__footer p-grid p-nogutter resource-config-footer">
    <div class="resource__footer__leftcorner-wrapper">
      <lib-button
        class="resource__footer--margin-right"
        (clicked)="cancel()"
        [label]="'RESOURCE_CONFIGURATION.CANCEL' | translate"
        [typeClass]="'button--secondary'"
        [sizeClass]="'button--small'"
        [loadingTopic]="LOADING_TOPIC_UPDATE"
      ></lib-button>
      <lib-button
        (clicked)="save()"
        [label]="'RESOURCE_CONFIGURATION.SAVE' | translate"
        [typeClass]="'button--primary'"
        [sizeClass]="'button--small'"
        [loadingTopic]="LOADING_TOPIC_UPDATE"
        [disabled]="!isFormValid"
      ></lib-button>
    </div>
  </div>
</ng-template>
