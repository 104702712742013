import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BillOfMaterialColSizes } from './bill-of-material.model';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { BillOfMaterial, TimeLeftIcon, MaterialType, FlushingPrinciple, ArticleDescription } from 'chronos-core-client';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bill-of-material',
  templateUrl: './bill-of-material.component.html',
  styleUrls: ['./bill-of-material.component.scss']
})
export class BillOfMaterialComponent implements OnInit, AfterViewInit {
  @ViewChild('dt') private dataTable: Table;
  @Input() public billOfMaterials: BillOfMaterial[];
  @Input() public scrollHeight = '';
  @Input() public showInactive = false;
  @Input() public colSizes: BillOfMaterialColSizes = {
    onHandColSize: '125px',
    openColSize: '125px',
    mountedColSize: '125px',
    nameColSize: '480px',
    articleColSize: '180px',
    statusColSize: '100px'
  };

  constructor(private translateService: TranslateService) {}

  public readonly TIME_LEFT_ICON = TimeLeftIcon;
  public readonly MATERIAL_TYPE = MaterialType;

  public tableColumns: any[];
  public filters: SelectItem[];
  public selectedFilter = FlushingPrinciple.MANUAL;

  public ngOnInit(): void {
    this.tableColumns = [{ field: 'mounted', header: 'MOUNTING.STATUS', width: this.colSizes.statusColSize, textAlign: 'left' }];

    if (this.showInactive) {
      this.tableColumns.push({ field: 'timeLeft', header: 'MOUNTING.TIME_LEFT', textAlign: 'left' });
    }

    this.tableColumns = [
      ...this.tableColumns,
      { field: 'externalArticleId', header: 'MOUNTING.ARTICLE', width: this.colSizes.articleColSize, textAlign: 'right' },
      { field: 'articleName', header: 'MOUNTING.NAME', width: this.colSizes.nameColSize, textAlign: 'left' },
      { field: 'mounted', header: 'MOUNTING.MOUNTED', width: this.colSizes.mountedColSize, textAlign: 'right' },
      { field: 'open', header: 'MOUNTING.OPEN', width: this.colSizes.openColSize, textAlign: 'right' },
      { field: 'onhand', header: 'MOUNTING.ON_HAND', width: this.colSizes.onHandColSize, textAlign: 'right' }
    ];

    this.filters = [
      { label: 'BILL_OF_MATERIAL.FILTER_TO_BE_MOUNTED', value: FlushingPrinciple.MANUAL },
      { label: 'BILL_OF_MATERIAL.FILTER_ALL', value: '' }
    ];
  }

  public ngAfterViewInit(): void {
    this.globalDataFilter(this.selectedFilter);
  }

  public globalDataFilter(filterValue: string): void {
    this.dataTable.filterGlobal(filterValue, 'equals');
  }

  public isPrimaryType(materialType: MaterialType): boolean {
    return materialType === MaterialType.PRIMARY;
  }

  public isAlternate(alternativeForBomId: number): boolean {
    return alternativeForBomId !== null;
  }

  public getTooltip(articleDescription: ArticleDescription): string {
    const prefixText = this.translateService.instant('MOUNTING.ALTERNATIVE_MATERIAL_PREFIX');
    const articleDescriptionContent = `${articleDescription.externalArticleId} (${articleDescription.articleName ?? '-'})`;
    const configurationDescriptionContent = `${articleDescription.externalConfigurationId} (${
      articleDescription.configurationName ?? '-'
    })`;

    return `${prefixText}:\n${articleDescriptionContent}\n${
      articleDescription.externalConfigurationId ? configurationDescriptionContent : '-'
    }`;
  }
}
