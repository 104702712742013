<section *ngIf="areDocumentsAvailable(); else noImagesAvailable" class="nc-document-list">
  <h2 class="nc-document-list__title">{{ 'QA_HISTORY.DOCUMENTS' | translate }}</h2>

  <div class="p-grid">
    <div class="p-col-4" *ngFor="let document of nonConformanceDocuments">
      <app-nc-document-item [document]="document" (openDocument)="openDocument(document)"></app-nc-document-item>
    </div>
  </div>
</section>
<ng-template #noImagesAvailable>
  <app-nc-defective-item [isFullscreen]="isFullscreen"></app-nc-defective-item>
</ng-template>
